import React, { useEffect, useRef, useState } from 'react'
import "./EditEscortProfile.css";
import PhoneInput from 'react-phone-input-2'
import downArrow from "../images/chevron-down.svg"
import crossIcon from "../images/Group 427318837 (1).svg";
import addImg from "../images/Group 427318773 purple.svg"
import Loader from './Loader';
import axios from 'axios';
import { baseURL, relativeURL } from '../APIS';

const EditEscortProfile = ({escortProfile}) => {
  const [escortInfo, setEscortInfo] = useState({
    user_id: "",
    full_name: "",
    about: "",
    age: "",
    phone_code: "+81",  
    mobile_no: "",
    line_number: "",
    email: "",
    password: "",
    hourly_price: "",
    image_upload: [], // remain
    ward: "", // remain
    latitude: 23.022505,
    longitude: 72.571365
  })
  const fileInputRef = useRef(null);
  const [uploadedOrgImages, setUploadedOrgImages] = useState([])
  const [uploadedTempImages, setUploadedTempImages] = useState([])
  const [uploadedTempImagesUrl, setUploadedTempImagesUrl] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [showRequired, setShowRequired] = useState(false);
  const [removeUploadedStr, setRemoveUploadedStr] = useState("")

  const wardOptions = ["Minato", "Chiyoda", "Chūō", "Shinjuku", "Bunkyō", "Taitō", "Sumida", "Kōtō", "Shinagawa", "Meguro", "Ōta", "Setagaya", "Shibuya", "Nakano", "Suginami", "Toshima", "Kita", "Arakawa", "Itabashi", "Nerima", "Adachi", "Katsushika", "Edogawa"];

  useEffect(() => {
    setEscortInfo(prevObject => ({
      ...prevObject,
      user_id: escortProfile.id,
      full_name: escortProfile.name,
      about: escortProfile.description,
      age: escortProfile.age,
      phone_code: escortProfile.phone_code,
      mobile_no: escortProfile.mobile_no,
      line_number: escortProfile.line_number,
      email: escortProfile.email,
      hourly_price: escortProfile.hourly_price,
      ward: escortProfile.ward
    }))
    setUploadedOrgImages(escortProfile.escort_images)
    setRemoveUploadedStr("")
    setUploadedTempImages([])
    setUploadedTempImagesUrl([])
  }, [])

  const inputs = [
    {type: 'text', name: "full_name", title: "Full Name"},
    {type: 'text', name: "about", title: "About"},
    {type: 'tel', name: "age", title: "Age"},
    {type: "condition", name: "phone_code", title: "Phone Code"},
    {type: 'tel', name: "mobile_no", title: "Mobile Number"},
    {type: 'tel', name: "line_number", title: "Line Number"},
    {type: 'email', name: "email", title: "Email"},
    {type: 'text', name: "password", title: "Password"},
    {type: 'tel', name: "hourly_price", title: "Hourly Price"},
    {type: "condition", name: "ward", title: "Ward"},
    {type: "condition", name: "image_upload", title: "Image Upload"},
  ]

  const handleInputChange = (e) => {
    if(e.target.name !== "email") {
      setEscortInfo(prevObject => ({
        ...prevObject,
        [e.target.name]: e.target.value
      }))
    }
  }

  const handleCountryCodeChange = (val) => {
    setEscortInfo(prevObject => ({
      ...prevObject,
      phone_code: `+${val}`
    }))
  }

  const removeUploadedOriginalImage = (item) => {
    if(removeUploadedStr) {
      setRemoveUploadedStr(removeUploadedStr+","+item.id)
      console.log(removeUploadedStr+","+item.id)
    } else {
      setRemoveUploadedStr(String(item.id))
      console.log(String(item.id))
    }
    let tempUploadedImages = uploadedOrgImages.filter((obj) => {
      if(obj.id === item.id) {
        return false
      } else {
        return true
      }
    })
    setUploadedOrgImages(tempUploadedImages)
  }

  const handleAddImage = () => {
    fileInputRef.current.click();
  }

  const handleUploadImageChange = (event) => {
    console.log(event)
    const file = event.target.files[0];
    if (file) {
      // const reader = new FileReader();
      // reader.onload = (e) => {
      //   const binaryData = e.target.result;
        // let formData = new FormData()
        // formData.append("images", file);
        setUploadedTempImages([...uploadedTempImages, file])
      // }
      // reader.readAsBinaryString(file);
      const reader1 = new FileReader();
      reader1.onload = (e) => {
        const binaryDataUrl = e.target.result;
        setUploadedTempImagesUrl([...uploadedTempImagesUrl, binaryDataUrl])
      }
      reader1.readAsDataURL(file);
      event.target.value = ''
    }
  }

  const removeUploadedTempImage = (idx) => {
    let tempData = uploadedTempImages
    tempData = [...tempData.slice(0, idx), ...tempData.slice(idx + 1)]
    setUploadedTempImages(tempData)
    let tempDataUrl = uploadedTempImagesUrl
    tempDataUrl = [...tempDataUrl.slice(0, idx), ...tempDataUrl.slice(idx + 1)]
    setUploadedTempImagesUrl(tempDataUrl)
  }

  const handleSubmit = () => {
    let obj = escortInfo;
    if(removeUploadedStr.length) {
      obj.remove_img_ids = removeUploadedStr
    }
    if(uploadedTempImages.length) {
      obj.image_upload = uploadedTempImages
    }
    const apiFormData = new FormData();
    Object.entries(obj).forEach((item) => {
      if(item[0] === "image_upload") {
        for (let i = 0; i < item[1].length; i++) {
          apiFormData.append('image_upload[]', item[1][i]);
        }
      } else {
        apiFormData.append(item[0], item[1])
      }
    })
    if((uploadedOrgImages.length || uploadedTempImages.length) && escortInfo.full_name && escortInfo.about && escortInfo.age && escortInfo.phone_code && escortInfo.mobile_no && escortInfo.line_number && escortInfo.email && escortInfo.hourly_price && escortInfo.ward) {
      setShowLoader(true)
      axios.post(baseURL + relativeURL.escortRegister, apiFormData, {
        headers: { 'Content-Type': 'multipart/form-data'}
      }).then((res) => {
        setShowLoader(false)
        console.log(res);
        window.location.reload()
      }).catch((err) => {
        console.error(err)
        setShowLoader(false)
      })
    } else {
      setShowRequired(true)
      // setTimeout(() => {
      //   setShowRequired(false)
      // }, 6000)
    }
  }

  return (
    <>
      <div className='editEscortProfileContainer w-100 px-1'>
        <Loader show={showLoader} />
        {inputs?.map((item, idx) => {
          return(
            <div className='w-100 mb-3' key={idx}>
              {item.type === "condition"
                ? <>
                    {item.name === "phone_code"
                      ? <>
                          <span className='myAccountKeys'>
                            {item.title} 
                            {showRequired 
                              ? <>
                                  {escortInfo[item.name] ? <></> : <span className='text-danger ms-2'>Required*</span>}
                                </>
                              : <></>
                            }
                          </span>
                          <div className='countryCodeContainer w-100'>
                            <div className='flag-code d-flex align-items-center justify-content-start w-100'>
                              <PhoneInput disableSearchIcon={true} searchPlaceholder="search" searchNotFound="No entries to show" enableSearch={true} value={escortInfo[item.name]} onChange={(val) => handleCountryCodeChange(val)} />
                              <div className='countryCode'>{escortInfo[item.name]}</div>
                            </div>
                          </div>
                        </>
                      : <></>
                    }
                    {item.name === "ward"
                      ? <>
                          <span className='myAccountKeys'>
                            {item.title} 
                            {showRequired 
                              ? <>
                                  {escortInfo[item.name] ? <></> : <span className='text-danger ms-2'>Required*</span>}
                                </>
                              : <></>
                            }
                          </span>
                          <div className='position-relative w-100 d-flex align-items-center justify-content-end'>
                            <img className='position-absolute bookingPagePriceDDImg' src={downArrow} alt='' />
                            <select className={'wardSelection border-primary text-primary border-0 d-flex justify-content-center align-items-center w-100 px-3 p-2' + (escortInfo[item.name] ? "" : ' wardPlaceholder')} value={escortInfo[item.name]} name={item.name} onChange={handleInputChange}>
                              <option value="" disabled className=''>-- Select Ward --</option>
                              {wardOptions?.map((opt, index) => {
                                return (
                                  <option key={index} value={opt} className=''>{opt}</option>
                                )
                              })}
                            </select>
                          </div>
                        </>
                      : <></>
                    }
                    {item.name === "image_upload"
                      ? <>
                          <span className='myAccountKeys'>
                            {item.title} 
                            {showRequired 
                              ? <>
                                  {(uploadedOrgImages.length || uploadedTempImages.length) ? <></> : <span className='text-danger ms-2'>Required*</span>}
                                </>
                              : <></>
                            }
                          </span>
                          <div className='row ms-0 w-100 g-3'>
                            {uploadedOrgImages?.map((item, index) => {
                              return(
                                <div key={index} className='col-6 editEscortImgColumns position-relative'>
                                  <div className='editEscortImage d-flex justify-content-end align-items-start' style={{backgroundImage: `url("${item.file_path}")`}}>
                                    <button className='bg-none border-0 mt-1 me-1' onClick={() => removeUploadedOriginalImage(item)}>
                                      <img src={crossIcon} alt='' />
                                    </button>
                                  </div>
                                </div>
                              )
                            })}
                            {uploadedTempImagesUrl?.map((img, index) => {
                              return(
                                <div key={index} className='col-6 editEscortImgColumns position-relative'>
                                  <div className='editEscortImage d-flex justify-content-end align-items-start' style={{backgroundImage: `url("${img}")`}}>
                                    {/* <img src={img} /> */}
                                    <button className='bg-none border-0 mt-1 me-1' onClick={() => removeUploadedTempImage(index)}>
                                      <img src={crossIcon} alt='' />
                                    </button>
                                  </div>
                                </div>
                              )
                            })}
                            <div className='col-6 editEscortImgColumns position-relative'>
                              <button className='editEscortImage bg-none d-flex justify-content-center align-items-center editEscortAddImageContainer' onClick={handleAddImage}>
                                <img src={addImg} alt='' style={{transform: 'rotate(45deg)'}} />
                              </button>
                            </div>
                            <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleUploadImageChange} accept='.jpg, .png, .jpeg' />
                          </div>
                        </>
                      : <></>
                    }
                  </>
                : <div className='w-100 d-flex flex-column align-items-start'>
                    <span className='myAccountKeys'>
                      {item.title} 
                      {item.title !== "Password"
                        ? <>
                            {showRequired 
                              ? <>
                                  {escortInfo[item.name] ? <></> : <span className='text-danger ms-2'>Required*</span>}
                                </>
                              : <></>
                            }
                          </>
                        : <></>
                      }
                    </span>
                    {item.name==="email"
                      ? <input className='w-100 escortProfileEditInputs bg-none px-3' type={item.type} name={item.name} value={escortInfo[item.name]} onChange={handleInputChange} readOnly={true} />
                      : <input className='w-100 escortProfileEditInputs bg-none px-3' type={item.type} name={item.name} value={escortInfo[item.name]} onChange={handleInputChange} />
                    }
                  </div>
              }
            </div>
          )
        })}
        <button className='border-0 w-100 bg-primary text-white editEscortSubmitBtn' onClick={() => handleSubmit()}>Submit</button>
      </div>
    </>
  )
}

export default EditEscortProfile