import MyAccount from './components/MyAccount';
import MyBookings from './components/MyBookings';
import BookingConfirmation from './components/BookingConfirmation';
import BookingPage from './components/BookingPage';
import Card from './components/Card';
import EnterMobileNumber from './components/EnterMobileNumber';
import FavouritesPage from './components/FavouritesPage';
import Home from './components/Home';
import Login from './components/Login';
import LoginForm from './components/LoginForm';
import PageNotFound from './components/PageNotFound';
import Signup from './components/Signup';
import ManageAvailablity from './components/ManageAvailablity';
import GetCurrentLocation from './components/GetCurrentLocation';
import EscortsLogin from './components/EscortsLogin';

const routesList = [
    { route: "/", component: <Home /> },
    { route: "/login", component: <Login /> },
    { route: "/signup", component: <Signup /> },
    { route: "/card", component: <Card /> },
    { route: "/enter-mobile-number", component: <EnterMobileNumber /> },
    { route: "/booking-page", component: <BookingPage /> },
    { route: "/booking-confirmed", component: <BookingConfirmation /> },
    { route: "/favorites", component: <FavouritesPage /> },
    { route: "/login-form", component: <LoginForm /> },
    { route: "/my-account", component: <MyAccount /> },
    { route: "/my-bookings", component: <MyBookings /> },
    { route: "/manage-availablity", component: <ManageAvailablity /> },
    { route: "/testing-mylocation", component: <GetCurrentLocation /> },
    { route: "/escort-login", component: <EscortsLogin /> },
    { route: "*", component: <PageNotFound /> }
]

const routes = {
    home: "/",
    login: "/login",
    signup: "/signup",
    card: "/card",
    enterMobileNumber: "/enter-mobile-number",
    bookingPage: "/booking-page",
    bookingConfirmed: "/booking-confirmed",
    favorites: "/favorites",
    loginForm: "/login-form",
    myAccount: "/my-account",
    myBookings: "/my-bookings",
    getCurrentLocation: "/testing-mylocation",
    manageAvailablity: "/manage-availablity",
    escortLogin: "/escort-login",
}
export { routesList, routes }