import './App.css';
import React, { useEffect, useState } from 'react';
// import Simple from './components/Simple';
import { routesList } from "./Routes";
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import Loader from './components/Loader';
import { baseURL, relativeURL } from './APIS';
import img1 from "./images/Rectangle 46.png";
import img2 from "./images/9c37f7bba95c5b13e82d80337f198302.png";
import img3 from "./images/Rectangle 46 (1).png";
import img4 from "./images/35d829e3071d852134cadc841b9936d5 (1).png";
// import { useGeolocated } from "react-geolocated";

const App = () => {
  const [showLoader, setShowLoader] = useState(false)
  const staticImages = [img1, img2, img3, img4]
  // const [imagesLoaded, setImagesLoaded] = useState(false);
  // const [tempPhotosForCache, setTempPhotosForCache] = useState([])
  // const { coords } =
  //   useGeolocated({
  //     positionOptions: {
  //       enableHighAccuracy: false,
  //     },
  //     userDecisionTimeout: 5000,
  //   });
  // console.log(coords)

  useEffect(() => {
    window.scrollTo(0, 0);
    let allStrings = sessionStorage.getItem("allStrings")
    if (!allStrings) {
      setShowLoader(true)
      axios.post(baseURL + relativeURL.getAllStrings).then(res => {
        setShowLoader(false)
        if (res.data.success) {
          sessionStorage.setItem("allStrings", JSON.stringify(res.data.data))
        }
      }).catch(err => {
        setShowLoader(false)
        console.log(err)
      })
    }
    let termsPrivacy = sessionStorage.getItem("termsPrivacy")
    if (!termsPrivacy) {
      setShowLoader(true)
      axios.post(baseURL + relativeURL.termsPrivacyPolicy).then(res => {
        setShowLoader(false)
        if (res.data.success) {
          sessionStorage.setItem("termsPrivacy", JSON.stringify(res.data.data))
        }
      }).catch(err => {
        setShowLoader(false)
        console.log(err)
      })
    }
    handlePhotosForCache()
  }, [])

  const handlePhotosForCache = () => {
    setShowLoader(true)
    let data = { user_id: "", filter: "" }
    let cachedImages = sessionStorage.getItem("cachedImages")
    if (cachedImages) {
      cachedImages = JSON.parse(cachedImages)
      setTimeout(() => {
        setShowLoader(false)
      })
      for(let imgPath of cachedImages.slice(0,5)) {
        const img = new Image();
        img.src = imgPath;
      }
      setTimeout(() => {
        for(let imgPath of cachedImages.slice(5, cachedImages.length)) {
          const img = new Image();
          img.src = imgPath;
        }
      }, 1000)
    } else {
      axios.post(baseURL + relativeURL.escortsList, data).then(res => {
        let photosForCache = []
        if (res.data.success) {
          let tempData = res.data.data
          for (let obj of tempData) {
            for (let photo of obj.escort_images) {
              photosForCache = [...photosForCache, photo.file_path]
            }
          }
          // setTempPhotosForCache(photosForCache?.reverse());
          photosForCache = photosForCache.reverse()
          sessionStorage.setItem("cachedImages", JSON.stringify(photosForCache))
          for (let imgPath of photosForCache.slice(0, 5)) {
            const img = new Image();
            img.src = imgPath;
          }
        }
        setShowLoader(false)
        setTimeout(() => {
          for (let imgPath of photosForCache.slice(5, photosForCache.length)) {
            const img = new Image();
            img.src = imgPath;
          }
        }, 1000)
      }).catch((err) => {
        setShowLoader(false)
        console.log(err)
      })
    }
  }

  return (
    <>
      <Loader show={showLoader} />
      {/* <Router basename='/projects/escorts-jp-frontend'> */}
      {!showLoader
        ? <Router>
            <Routes>
              {routesList?.map((item, index) => {
                return (
                  <Route key={index} path={item.route} element={item.component} />
                )
              })}
            </Routes>
          </Router>
        : <></>
      }
      {staticImages?.map((img, index) => {
        return (
          <img style={{ display: "none" }} key={index} src={img} alt='' />
        )
      })}
      {/* { tempPhotosForCache.length
        ? <div style={{display: "none"}} >
            {tempPhotosForCache?.map((img,index) => {
              return(
                <img key={index} src={img} alt='' />
              )
            })}
          </div>
        : <></>
      } */}
    </>
  );
}

export default App;
