import React, { useEffect, useState } from 'react'
import "./EscortLogin.css"
import logoImg from "../images/Group 427318752-3.png"
import mailImg from "../images/mail.png"
import { useNavigate } from 'react-router-dom'
import { routes } from '../Routes'
import lockImg from "../images/Mask group (5).png"
import Loader from './Loader'
import axios from 'axios'
import { baseURL, relativeURL } from '../APIS'
import { messaging } from "../firebase";
import { getToken } from "firebase/messaging";
import profileImg from "../images/Mask group.png"
import headerImg from "../images/Group 427318752.png"

const EscortsLogin = () => {
  const navigate = useNavigate()

  const [allStrings, setAllString] = useState({})
  const sessionStoreAllString = sessionStorage.getItem("allStrings");
  const [email, setEmail] = useState("")
  const [pwd, setPwd] = useState("")
  const [showLoader, setShowLoader] = useState(false)
  const [showMissingError, setShowMissingError] = useState(false);
  const [showAPIError, setShowAPIError] = useState("");
  const [isEscort, setIsEscort] = useState(false)
  const [userLoggedIn, setUserLoggedIn] = useState(false)

  useEffect(() => {
    let tempAllStrings = sessionStorage.getItem("allStrings")
    if (tempAllStrings) {
      tempAllStrings = JSON.parse(tempAllStrings);
      setAllString(tempAllStrings)
    }
  }, [sessionStoreAllString])

  useEffect(() => {
    if (sessionStorage.getItem("userVerified")) {
      setUserLoggedIn(true)
      let userDetails = JSON.parse(sessionStorage.getItem("userVerified"))
      if (userDetails.user_role === "escorts") {
        setIsEscort(true)
      }
    } else {
      setUserLoggedIn(false)
    }
  }, [])

  const handleSubmit = () => {
    if(email && pwd)  {
      let data = {
        email: email,
        password: pwd
      }
      setShowLoader(true)
      axios.post(baseURL + relativeURL.escortLogin, data).then((res) => {
        setShowLoader(false)
        console.log(res)
        if(res.data.success) {
          sessionStorage.setItem("userVerified", JSON.stringify(res.data.data))
          let user = res.data.data
          requestPermission(user.id)
          if(user.user_role === "escorts") {
            // navigate(routes.myAccount)
            navigate(routes.card)
          }
        }
      }).catch((err) => {
        console.error(err)
        setShowLoader(false)
        setShowAPIError(err.response.data.message)
        setPwd("")
        setTimeout(() => {
          setShowAPIError("")
        }, 3000)
      })
    } else {
      setShowMissingError(true)
      setTimeout(() => {
        setShowMissingError(false)
      }, 3000)
    }
  }

  const requestPermission = async (userId) => {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then(async (registration) => {
      setShowLoader(true)
      console.log('Service Worker registered with scope:', registration.scope);
      // Request notification permissions and get FCM token
      const swRegistration = await navigator.serviceWorker.ready;
      console.log("swRegistration", swRegistration)
      const permission = await Notification.requestPermission();
      console.log("permission", permission)
      if(permission === "granted" || permission === "prompt") {
        // console.log('Notification permission granted.');
        // Get the FCM token
        return getToken(messaging, {
          vapidKey:
            "BHNdTL_GGvGssYcC5TVlYL7uqx_Sk_6scfUmljHSTHVHySs0o0H-7pdG4Zs9Mo3Hb3WHSsyD-nGB1nvMCri78W0",
          serviceWorkerRegistration: swRegistration
        });
      } else if (permission === "denied") {
        setShowLoader(false)
        console.log("You denied permission for notifications");
      }
    })
    .then((token) => {
      setShowLoader(false)
      if (token) {
        console.log("Token Gen :", token);
        if(userId){
          const data = {
            user_id: userId,
            device_token: token 
          }
          setShowLoader(true)
          axios.post(baseURL + relativeURL.userUpdateDeviceToken, data).then((res) => {
            setShowLoader(false)
            console.log(res)
          }).catch((err) => {
            setShowLoader(false)
            console.log(err)
          })
        }
      }
      // You can send the token to your server for further use
    })
    .catch((error) => {
      console.error('Error initializing Firebase:', error);
    });
  }

  const handleDropdown = () => {
    let card = document.querySelector(".cardDropdown"); //declearing profile card element
    card.classList.toggle("hidden")
  }

  return (
    <>
      <Loader show={showLoader} />
      <div className='loginFormContainer position-relative d-flex justify-content-center align-items-center'>
        <div className='position-absolute top-0 w-100 bg-white p-3'>
          <div className='enterMobileNumberHeader d-flex justify-content-between w-100 align-items-center' style={{maxWidth: "none"}}>
            <div className='d-flex justify-content-start align-items-center' onClick={() => navigate(routes.home)}>
              <div><img className='headerImg' src={headerImg} alt='' /></div>
              <div className='cardHeaderTitle headertext'>EscortsJP.com</div>
            </div>
            <div className="profile_dropdown">
              <ul>
                <a href="*" onClick={(e) => { e.preventDefault() }} className="display-pictureDropdown">
                  <img src={profileImg} alt='' onClick={() => {
                    if (userLoggedIn) {
                      handleDropdown()
                    } else {
                      sessionStorage.setItem("routeFrom", routes.loginForm);
                      sessionStorage.setItem("showModalLoginBtn", JSON.stringify(true));
                      navigate(routes.enterMobileNumber);
                    }
                  }} />
                </a>
              </ul>
              <div className="cardDropdown position-relative">
                <ul>
                  <li>
                    {userLoggedIn
                      ? <>
                        <a href="*" onClick={(e) => {
                          e.preventDefault();
                          navigate(routes.myAccount);
                        }}> {allStrings.my_account ? allStrings.my_account : "My Account"} </a>
                        <a href="*" onClick={(e) => {
                          e.preventDefault();
                          navigate(routes.myBookings);
                        }}> {allStrings.my_bookings ? allStrings.my_bookings : "My Bookings"} </a>
                        {isEscort
                          ? <a href="*" onClick={(e) => {
                            e.preventDefault();
                            navigate(routes.manageAvailablity);
                          }}>
                            {allStrings.manage_availability ? allStrings.manage_availability : "Manage Availablity"}
                          </a>
                          : <></>
                        }
                        <a href="*" onClick={(e) => {
                          e.preventDefault();
                          sessionStorage.removeItem("userVerified");
                          navigate(routes.home)
                          // window.location.reload()
                        }}> {allStrings.log_out ? allStrings.log_out : "Log Out"} </a>
                      </>
                      : <></>
                    }
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='loginFormContent d-flex justify-content-center flex-column align-items-center'>
          <img className='' src={logoImg} alt='' onClick={() => navigate(routes.home)} />
          <div className='loginFormBrandTitle mt-1' onClick={() => navigate(routes.home)}>EscortsJP.com</div>
          <div className='loginFormWelcomeMsg'>{allStrings.welcome_back ? allStrings.welcome_back : "Welcome Back!"}</div>
          <div className='loginFormWelcomeSubMsg'>{allStrings.let_s_login_for_dream_night ? allStrings.let_s_login_for_dream_night : "Let's login for dream night"}</div>
          <div className='loginFormInputContainer border-primary w-100 mt-4 mb-2 p-2 d-flex justify-content-between align-items-center'>
            <img className='ms-1' src={mailImg} alt='' />
            <input className='border-0 bg-none ms-3 w-100' type='text' placeholder={allStrings.email ? allStrings.email : "Email"} value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className='loginFormInputContainer border-primary w-100 mt-1 mb-2 p-2 d-flex justify-content-between align-items-center'>
            <img className='ms-1' src={lockImg} alt='' />
            <input className='border-0 bg-none ms-3 w-100' type='password' placeholder={allStrings.password ? allStrings.password : "Password"} value={pwd} onChange={(e) => setPwd(e.target.value)} />
          </div>
          <button className='loginFormGetOtpBtn border-0 bg-primary text-white w-100' onClick={() => handleSubmit()}>
            {allStrings.login_now ? allStrings.login_now : "Login Now"}
          </button>
          {showMissingError
            ? <div className='text-danger mt-3'>{allStrings.please_enter_email_and_password ? allStrings.please_enter_email_and_password : "Please enter Email and Password"} </div>
            : <></>
          }
          {showAPIError
            ? <div className='text-danger mt-3'>{showAPIError} </div>
            : <></>
          }
        </div>
      </div>
    </>
  )
}

export default EscortsLogin