import React, { useEffect, useState } from 'react'
import "./Login.css"
import headerImg from "../images/Group 427318752.svg"
import headerImg2 from "../images/Group 427318752.png"
import callIcon from "../images/Mask group (4).png"
import profileImg from "../images/Mask group.png"
import { useNavigate } from 'react-router-dom'
import { routes } from '../Routes'

const Login = () => {
  const navigate = useNavigate();
  const [allStrings, setAllString] = useState({})
  const sessionStoreAllString = sessionStorage.getItem("allStrings");
  const [termsPrivacy, setTermsPrivacy] = useState({})
  const sessionStoreTermsPrivacy = sessionStorage.getItem("termsPrivacy");
  const [userLoggedIn, setUserLoggedIn] = useState(false)
  const [isEscort, setIsEscort] = useState(false)

  useEffect(() => {
    let escort = sessionStorage.getItem("escortBooking")
    if (escort) {
    } else {
      navigate(routes.card)
    }
    if(sessionStorage.getItem("userVerified")) {
      setUserLoggedIn(true)
      let userDetails = JSON.parse(sessionStorage.getItem("userVerified"))
      if(userDetails.user_role === "escorts") {
        setIsEscort(true)
      } else {
        setIsEscort(false)
      }
    } else {
      setUserLoggedIn(false)
    }
  }, [navigate])

  useEffect(() => {
    let tempAllStrings = sessionStorage.getItem("allStrings")
    if (tempAllStrings) {
      tempAllStrings = JSON.parse(tempAllStrings);
      setAllString(tempAllStrings)
    }
  }, [sessionStoreAllString])

  useEffect(() => {
    let tempTermsPrivacy = sessionStorage.getItem("termsPrivacy")
    if (tempTermsPrivacy) {
      tempTermsPrivacy = JSON.parse(tempTermsPrivacy);
      setTermsPrivacy(tempTermsPrivacy)
    }
  }, [sessionStoreTermsPrivacy])

  const onOurTermsClick = () => {
    const url = termsPrivacy.terms_condition_link;
    // Open the URL in a new tab
    window.open(url, '_blank');
  }
  const onOurPrivacyClick = () => {
    const url = termsPrivacy.privacy_policy_link;
    // Open the URL in a new tab
    window.open(url, '_blank');
  }

  const handleDropdown = () => {
    let card = document.querySelector(".cardDropdown"); //declearing profile card element
    card.classList.toggle("hidden")
  }

  return (
    <>
      <div className='loginContainer d-flex justify-content-center align-items-center'>
        <div className='loginContainer2 d-flex justify-content-center align-items-center flex-column'>
          <div className='loginContainerHeader bg-white p-3 d-flex justify-content-between w-100 align-items-center mb-3'>
            <div className='d-flex justify-content-start align-items-center' onClick={() => navigate(routes.home)}>
              <div><img className='headerImg' src={headerImg2} alt='' /></div>
              <div className='cardHeaderTitle headertext'>EscortsJP.com</div>
            </div>
            <div className="profile_dropdown">
              <ul>
                <a href="*" onClick={(e) => { e.preventDefault() }} className="display-pictureDropdown">
                  <img src={profileImg} alt='' onClick={() => {
                    if (userLoggedIn) {
                      handleDropdown()
                    } else {
                      sessionStorage.setItem("routeFrom", routes.loginForm);
                      sessionStorage.setItem("showModalLoginBtn", JSON.stringify(false));
                      navigate(routes.enterMobileNumber);
                    }
                  }} />
                </a>
              </ul>
              <div className="cardDropdown position-relative">
                <ul>
                  <li>
                    {userLoggedIn
                      ? <>
                        <a href="*" onClick={(e) => {
                          e.preventDefault();
                          navigate(routes.myAccount);
                        }}> {allStrings.my_account ? allStrings.my_account : "My Account"} </a>
                        <a href="*" onClick={(e) => {
                          e.preventDefault();
                          navigate(routes.myBookings);
                        }}> {allStrings.my_bookings ? allStrings.my_bookings : "My Bookings"} </a>
                        {isEscort
                          ? <a href="*" onClick={(e) => {
                            e.preventDefault();
                            navigate(routes.manageAvailablity);
                          }}>
                            {allStrings.manage_availability ? allStrings.manage_availability : "Manage Availablity"}
                          </a>
                          : <></>
                        }
                        <a href="*" onClick={(e) => {
                          e.preventDefault();
                          sessionStorage.removeItem("userVerified");
                          navigate(routes.home)
                          // window.location.reload()
                        }}> {allStrings.log_out ? allStrings.log_out : "Log Out"} </a>
                      </>
                      : <></>
                    }
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='loginContent d-flex justify-content-center align-items-center flex-column p-3'>
            {/* <div className='loginUpperContainer d-flex justify-content-center align-items-center flex-column'> */}
            <div className='d-flex flex-column align-items-center' onClick={() => navigate(routes.home)}>
              <img className='mb-3' src={headerImg} alt='' />
              <div className='loginTitle text-white mt-1'>EscortsJP.com</div>
            </div>
            <div className='loginSubTitle text-white mt-5 mb-2'>{Object.keys(allStrings).length ? allStrings.to_continue_your_booking__please_login : ""}</div>
            <div className='loginDesc text-white mt-3'>
              {Object.keys(allStrings).length ? allStrings.by_clicking_continue__you_agree_with + " " : ""}
              <span className='loginDescLink' onClick={() => onOurTermsClick()}>
                {Object.keys(allStrings).length ? allStrings.our_terms + " " : ""}
              </span>
              {Object.keys(allStrings).length ? allStrings.learn_how_we_process_your_data_in + " " : ""}
              <span className='loginDescLink' onClick={() => onOurPrivacyClick()}>
                {Object.keys(allStrings).length ? allStrings.our_privacy_policy_and_cookies : ""}
              </span>
            </div>
            {/* </div>
            <div className='loginLowerContainer d-flex justify-content-center align-items-center flex-column w-100 pb-5'> */}
            <button className='loginBtn mb-5 position-relative border-0 bg-white d-flex justify-content-around align-items-center w-100' onClick={() => navigate(routes.enterMobileNumber)}>
              <img className='loginBtnIcon position-absolute' src={callIcon} alt='' />
              <div className='loginBtnDesc d-flex align-items-center border-0'>{Object.keys(allStrings).length ? allStrings.login_with_phone : ""}</div>
            </button>
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Login