import axios from 'axios'
import React, { useEffect } from 'react'
import { baseURL, relativeURL } from '../APIS'
// import { convertDistance, getDistance } from 'geolib';
// import { messaging } from "../firebase";
// import { getToken } from "firebase/messaging";

const GetCurrentLocation = () => {
  // const [coords, setCoords] = useState({})

  useEffect(() => {
    // navigator.serviceWorker.getRegistrations().then((registrations) => {
      // Unregister all service workers
      // for (const registration of registrations) {
        // console.log(registration)
        // registration.unregister();
      // }
      const data = {
        escort_fcm_token:"",
        user_fcm_token: "dtPCuKKXhULmJyRp4dp1t6:APA91bG5HU960aF1-DPeTmSGd3xQkHqDOKw-XjmDEIWI_coArRF3--7fdWI6Lqu3u_7vHyaTodRFcKBTlkKiFHkkq_2XwpIQb4gN_-9XArrGHkGDIKlaAtnzwePD8s9AEainNlq9HK7Z"
      }
      axios.post(baseURL + relativeURL.testNotification, data).then((res) => {
        console.log("res::", res)
      }).catch((err) => {
        console.log("err::", err)
      })
    // });  
    // if (navigator.geolocation) {
    //   navigator.permissions.query({ name: "geolocation" }).then((result) => {
    //     if (result.state === "granted") {
    //       getLocation()
    //     } else if (result.state === "prompt") {
    //       getLocation()
    //     } else if (result.state === "denied") {
    //       alert("Please enable location access in your browser's settings.");
    //     }
    //   }).catch(err => console.log(err));
    // } else {
    //   console.log("Geolocation is not supported by this browser.");
    // }

  }, [])
  
  // const getLocation = () => {
  //   const successCallback = (position) => {
  //     let temp = {latitude: position.coords.latitude, longitude: position.coords.longitude}
  //     let destination = { latitude: 23.075916814892928, longitude: 72.5093513350064 }
  //     let distance = getDistance(temp, destination, 1)
  //     console.log(convertDistance(distance, "mi"));
  //     setCoords({latitude: position.coords.latitude, longitude: position.coords.longitude})
  //   };
    
  //   const errorCallback = (error) => {
  //     console.log(error);
  //   };
    
  //   let options = { enableHighAccuracy: true, maximumAge: 5000 }
  //   navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
  // }

  // const requestPermission = async () => {
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker.register('/projects/escorts-jp-frontend/firebase-messaging-sw.js')
  //       .then(async (registration) => {
  //         // console.log('Service Worker registered with scope:', registration.scope);
  //         const permission = await Notification.requestPermission();
  //         if (permission === "granted") {
  //           // Generate Token
  //           const token = await getToken(messaging, {
  //             vapidKey:
  //               "BHNdTL_GGvGssYcC5TVlYL7uqx_Sk_6scfUmljHSTHVHySs0o0H-7pdG4Zs9Mo3Hb3WHSsyD-nGB1nvMCri78W0",
  //           });
  //           console.log("Token Gen", token);
  //           // Send this token  to server ( db)
  //         } else if (permission === "denied") {
  //           alert("You denied for the notification");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('Service Worker registration failed:', error);
  //       });
  //   }
  // }

  return (
    <>
      {/* <div className='mt-5'>
        {Object.keys(coords).length
          ? <>
              Latitude : {coords?.latitude}
              <br />
              Longitude : {coords?.longitude}
            </>
          : <div className='w-75 text-center'>
              Please enable location access in your browser's settings.
            </div>
        }
      </div> */}
    </>
  )
}

export default GetCurrentLocation