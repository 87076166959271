import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB0uOrTXmhvOT1mtwc-_PHHcCUs6scpXhA",
  authDomain: "escort-jp.firebaseapp.com",
  projectId: "escort-jp",
  storageBucket: "escort-jp.appspot.com",
  messagingSenderId: "991611183701",
  appId: "1:991611183701:web:77d9f1e84039753867aadc",
  measurementId: "G-KGWQ8CTMST"
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

onMessage(messaging, (payload) => {
  // console.log('Message received. ', payload);

  const notificationTitle = payload.notification.title || "Default Title";
  const notificationOptions = {
    body: payload.notification.body,
    icon: payload.notification.icon,
  };

  new Notification(notificationTitle, notificationOptions);
});