import React, { useEffect, useState } from 'react'
import "./BookingConfirmation.css"
import { useNavigate } from 'react-router-dom';
import { routes } from '../Routes';
import leftArrow from "../images/arrow-left.svg"
import watsappImg from "../images/whatsapp (1) 1.svg"
import lineImg from "../images/line 1.svg"
import homeImg from "../images/home (1).svg"
// import { convertDistance, getDistance } from 'geolib';
import dayjs from 'dayjs';

const BookingConfirmation = () => {
  const navigate = useNavigate();
  const [character, setCharacter] = useState({})
  const [allStrings, setAllString] = useState({})
  const sessionStoreAllString = sessionStorage.getItem("allStrings");
  // const [distance, setDistance] = useState(0)
  // const averageSpeed = 21.748  // in miles per hour 35km/h
  // const [hours, setHours] = useState(0);
  // const [minutes, setMinutes] = useState(0);
  const [showBookNow, setShowBookNow] = useState(false)
  const [bookingDetails, setBookingDetails] = useState()
  // const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let escort = sessionStorage.getItem("escortBooking")
    if (escort) {
      if(sessionStorage.getItem("showBookNow")){
        let tempBookNow = JSON.parse(sessionStorage.getItem("showBookNow"))
        setShowBookNow(tempBookNow)
        if(tempBookNow === false) {
          setBookingDetails(JSON.parse(sessionStorage.getItem("bookingDetails")))
        }
      }
      escort = JSON.parse(escort)
      setCharacter(escort)
      // let escortCoords = {};
      // if(escort.latitude) {
      //   escortCoords = { latitude : escort.latitude, longitude : escort.longitude }
      // } else {
      //   escortCoords = { latitude : `22°18'25.9"N`, longitude : `73°10'52.3"E` }
      // }
      // let clientCoords = JSON.parse(sessionStorage.getItem("clientLatLon"))
      // let tempDistance = getDistance(escortCoords, clientCoords, 1)
      // tempDistance = convertDistance(tempDistance, "mi")
      // setDistance(Math.floor(tempDistance));
      // let timeInHours = Math.floor(tempDistance) / averageSpeed
      // const tempHours = Math.floor(timeInHours);
      // const tempMinutes = Math.floor((timeInHours - tempHours) * 60);
      // setHours(tempHours)
      // setMinutes(tempMinutes)
      // const tempSeconds = Math.floor(((timeInHours - tempHours) * 60 - tempMinutes) * 60);
    } else {
      navigate(routes.card)
    }
  }, [navigate])

  useEffect(() => {
    let tempAllStrings = sessionStorage.getItem("allStrings")
    if(tempAllStrings) {
      tempAllStrings = JSON.parse(tempAllStrings);
      setAllString(tempAllStrings)
    }
  }, [sessionStoreAllString])

  const handleRedirectToWatsapp = () => {
    let escortBooking = sessionStorage.getItem("escortBooking")
    escortBooking = JSON.parse(escortBooking);
    const url = 'https://wa.me/' + escortBooking.mobile_no;
    // Open the URL in a new tab
    window.open(url, '_blank');
  }

  const handleRedirectToLine = () => {
    let escortBooking = sessionStorage.getItem("escortBooking")
    escortBooking = JSON.parse(escortBooking);
    const url = `https://line.me/R/ti/p/${encodeURI(escortBooking.line_number)}`;
    // Open the URL in a new tab
    window.open(url, '_blank');
  }

  return (
    <div className='bookingConfirmContainer p-3 d-flex justify-content-start align-items-center flex-column'>
      <div className='bookingConfirmHeading d-flex w-100 justify-content-center align-items-center position-relative mb-4'>
        <button className='backBtn button my-2 border-0' onClick={() => navigate(routes.card)}>
          <img src={leftArrow} alt='' />
        </button>
        <div className='bookingConfirmHeadingTitle text-primary d-flex'>
          {Object.keys(allStrings).length ? allStrings.booking_confirmation : ""}
        </div>
      </div>
      <div className='bookingConfirmationCard mb-2 p-4 d-flex align-items-center justify-content-center flex-column'>
        <div className='card m-0 mb-1 border-0 justify-content-between' style={{ backgroundImage: 'url(' + (Object.keys(character).length ? character.escort_images[0].file_path : "") + ')' }}>
          <div className='mt-2 mx-2 d-flex justify-content-center imageCounterContainer'>
          </div>
          <div className='cardInfoContainer p-2 d-flex justify-content-end align-items-center flex-column'>
            <div className='escortNameText mt-2 mb-1 d-flex justify-content-center align-items-center text-white'>
              {Object.keys(character).length ? (character.name + ", " + character.age) : ""}
            </div>
            {/* {showBookNow 
              ? <div className='escortNearBy'>{distance ? `${distance} Miles` : "1 Mile"}</div>
              : <></>
            } */}
          </div>
        </div>
        <div className='bookingConfirmTitle text-primary mt-3 my-2'>
          {Object.keys(allStrings).length ? allStrings.booking_confirmed : ""}
        </div>
        <div className='bookingConfirmDesc mt'>
          {showBookNow 
            ? <>
                {/* {Object.keys(character).length ? `${character.name} ${(Object.keys(allStrings).length ? allStrings.will_arrive_at_your_place_in : "will arrive at your place in")} ${hours ? `${hours} hrs` : ""} ${minutes ? `${minutes} mins` : ""} ${minutes === 0 && hours === 0 ? `5 mins` : ""}` : ""} */}
                {Object.keys(character).length ? `${character.name} ${(allStrings.will_arrive_at_your_place_in_30_minutes ? allStrings.will_arrive_at_your_place_in_30_minutes : "will arrive at your place in 30 minutes")} ` : ""}
              </>
            : <>
                {Object.keys(character).length ? `${character.name} will arrive at your place at ${dayjs(bookingDetails.booking_date).format("DD-MM-YYYY")}` : ""}
              </>
          }
        </div>
      </div>
      <div className='d-flex justify-content-start align-items-center flex-column mt-4'>
        <div className='chatWith mb-1'>{Object.keys(character).length ? ((Object.keys(allStrings).length ? allStrings.chat_with : "") + " " + character.name) : ""}</div>
        <div className='d-flex justify-content-center align-item-center mt-2'>
          <button className='border-0 button' onClick={() => handleRedirectToWatsapp()}>
            <img className='border-0 me-2' src={watsappImg} alt='' />
          </button>
          <button className='border-0 button' onClick={() => handleRedirectToLine()}>
            <img className='border-0 ms-2' src={lineImg} alt='' />
          </button>
        </div>
        <div className='d-flex justify-content-center align-item-center mt-3 bookingConfirmHomeBtn'>
          <button className='bg-primary border-0 text-white py-2 d-flex justify-content-center align-item-center' onClick={() => navigate(routes.home)}>
            <img className='me-2' src={homeImg} alt='' />
            {Object.keys(allStrings).length ? allStrings.home : ""}
          </button>
        </div>
      </div>
    </div>
  )
}

export default BookingConfirmation