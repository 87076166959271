// const baseURL = "https://testweb4you.com/projects/escorts-jp/api/v1/";
const baseURL = "https://escortsjp.com/admin/api/v1/";
const relativeURL = {
  escortsList: "escorts/list",
  favouriteEscort: "favourite/escort",
  sendOtp: "sent/otp",
  verifyOtp: "verify/otp",
  userUpdateProfile: "user/update/profile",
  escortGetTiming: "escort/get/timing",
  addUpdateAvailability: "escort/availability/add",
  escortAvailabilityList: "escort/availability/list",
  escortAvailabilityDelete: "escort/availability/delete",
  // countryList: "country/list",
  // stateList: "state/list",
  escortsDateWiseAvl: "escorts/date/wise/availability",
  escortsBooking: "escorts/booking",
  getProfile: "escort/profile",
  bookingList: "booking/list",
  getAllStrings: "get/all/string",
  termsPrivacyPolicy: "terms-privacypolicy/list",
  updateLocation: "user/update/location",
  testNotification: "test/notification",
  userUpdateDeviceToken: "user/update/device/token",
  escortLogin: "escort/login",
  escortRegister: "register",
}

export { baseURL, relativeURL }