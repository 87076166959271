import React from 'react'
import Help from './Help'
// import Simple from './Simple'

const Home = () => {
  return (
    <>
      <Help />
    </>
  )
}

export default Home