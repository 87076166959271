import React, { useEffect, useState } from 'react'
import orangeLogout from "../images/orange-logout.svg"
import blueCart from "../images/blue-cart-image.svg"
import greenCalendar from "../images/green manage calendar.svg"
import purpleUser from "../images/purple user icon.svg"
import { routes } from '../Routes'
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
  const navigate = useNavigate()
  const [allStrings, setAllString] = useState({})
  const sessionStoreAllString = sessionStorage.getItem("allStrings");

  const cards = [
    {title: allStrings.upcoming_bookings ? allStrings.upcoming_bookings : "Upcoming Bookings", img: blueCart, class: "dashboard-card-bg-blue", route: routes.myBookings},
    {title: allStrings.my_account ? allStrings.my_account : "My Account", img: purpleUser, class: "dashboard-card-bg-purple", route: routes.myAccount},
    {title: allStrings.manage_availability ? allStrings.manage_availability : "Manage Availability", img: greenCalendar, class: "dashboard-card-bg-green", route: routes.manageAvailablity},
    {title: allStrings.logout ? allStrings.logout : "Logout", img: orangeLogout, class: "dashboard-card-bg-orange", route: routes.home}
  ]

  useEffect(() => {
    let tempAllStrings = sessionStorage.getItem("allStrings")
    if (tempAllStrings) {
      tempAllStrings = JSON.parse(tempAllStrings);
      setAllString(tempAllStrings)
    }
  }, [sessionStoreAllString])

  const handleRouting = (route) => {
    if(route === routes.myBookings) {
      sessionStorage.setItem("upcomingBooking", JSON.stringify(true))
    } else if (route === routes.home) {
      sessionStorage.removeItem("userVerified");
    }
    navigate(route)
  }

  return (
    <>
      <div className="w-100 d-flex flex-column align-items-start w-100">
        {cards.map((item, idx) => {
          return(
            <button key={idx} className='py-3 px-4 w-100 bg-white dashboard-card mb-3 border-0' onClick={() => handleRouting(item.route)}>
              {/* <div className='dashboard-card-heading mb-2 text-start'>
                {item.title}
              </div> */}
              <div className='d-flex justify-content-start align-items-center'>
                <div className={`p-2 dashboard-card-img-container ${item.class} me-3 d-flex justify-content-center align-items-center`}>
                  <img src={item.img} alt='' height={32} width={32} />
                </div>
                <div className='dashboard-card-value text-start'> {item.title} </div>
              </div>
            </button>
          )
        })}
      </div>
    </>
  )
}

export default Dashboard