import React, { useState, useEffect, useRef } from 'react'
import { StyledEngineProvider } from '@mui/styled-engine'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import dayjs from 'dayjs'
import "./ManageAvailablity.css"
import closeImg from "../images/Close Icon.svg"
import editIcon from "../images/Mask group (3).svg"
import axios from 'axios';
import Loader from "./Loader";
import { baseURL, relativeURL } from '../APIS';
import dangerIcon from "../images/exclamation (1).svg";
import deleteIcon from "../images/trash 1.svg"
import leftArrow from "../images/arrow-left.svg"
import { routes } from '../Routes';
// import siteSettingsIcon from "../images/Site Settings.svg"
import { useNavigate } from 'react-router-dom'

const ManageAvailablity = () => {
  const navigate = useNavigate()
  const [showLoader, setShowLoader] = useState(false)
  const [dateRangeVal, setDateRangeVal] = useState(["", ""])
  const [dateSelectionModal, setDateSelectionModal] = useState()
  const [slotsEditModal, setSlotsEditModal] = useState()
  const [dateDeleteModal, setDateDeleteModal] = useState()
  const dateSelectionModalRef = useRef();
  const slotsEditModalRef = useRef();
  const confirmDeleteDateRef = useRef();
  const [allStrings, setAllString] = useState({})
  const sessionStoreAllString = sessionStorage.getItem("allStrings");
  const [availableSlotsRange, setAvailableSlotsRange] = useState([])
  const [showSlots, setShowSlots] = useState(false)
  const [timeSlots, setTimeSlots] = useState([]);
  const [slotIds, setSlotIds] = useState([])
  const [editIndex, setEditIndex] = useState("")
  const [selectedDateAndTimeSlots, setSelectedDateAndTimeSlots] = useState([])
  const [fetchAvlErr, setFetchAvlErr] = useState("")
  const [getTimingErr, setGetTimingErr] = useState("")
  const [showSelectionErr, setShowSelectionErr] = useState("")
  const [userDetails, setUserDetails] = useState()
  // const enableLocationRef = useRef(null);
  // const [enableLocationModal, setEnableLocationModal] = useState();

  const shortcutsItems = [
    {
      label: 'Next Week',
      getValue: () => {
        let today = dayjs();
        if(dateRangeVal[0] && dateRangeVal[1]) {
          today = dayjs(dateRangeVal[1])
        }
        return [today.endOf('week').add(1, 'day'), today.endOf('week').add(7, 'day')];
      },
    },
    {
      label: 'First Available Day',
      getValue: ({ isValid }) => {
        // const today = dayjs();
        // return [today.add(1, 'day'), today.endOf('month')];
        const today = dayjs();
        // const nextSaturday =
        //   today.day() <= 6
        //     ? today.add(6 - today.day(), 'day')
        //     : today.add(7 + 6 - today.day(), 'day');

        let maxAttempts = 200;
        let solution = [today, today];
        while (maxAttempts > 0 && !isValid(solution)) {
          if(today.get('month')===new Date().getMonth()) {
            solution = [solution[0].add(1, 'day'), solution[1].add(1, 'day')];
            maxAttempts -= 1;
          }
        }
        if(today.get('month')===new Date().getMonth()) {
          return solution;
        }
      },
    },
    {
      label: 'Next Month',
      getValue: () => {
        let today = dayjs();
        if(dateRangeVal[0] && dateRangeVal[1]) {
          today = dayjs(dateRangeVal[1])
        }
        const startOfNextMonth = today.endOf('month').add(1, 'day');
        return [startOfNextMonth, startOfNextMonth.endOf('month')];
      },
    },
  ];

  useEffect(() => {
    let tempTimeSlots = sessionStorage.getItem("escortGetTiming");
    let tempUserDetails = sessionStorage.getItem("userVerified")
    if(tempUserDetails) {
      if(tempUserDetails.user_role==="client") {
        navigate(routes.card)
      } else {
        tempUserDetails = JSON.parse(tempUserDetails);
        setUserDetails(tempUserDetails)
        if(tempTimeSlots) {
          setTimeSlots(JSON.parse(tempTimeSlots))
          fetchAvailablities(tempUserDetails.id)
        } else {
          setShowLoader(true)
          axios.post(baseURL + relativeURL.escortGetTiming).then((res) => {
            setShowLoader(false)
            if(res.data.success) {
              setTimeSlots(res.data.data)
              sessionStorage.setItem("escortGetTiming", JSON.stringify(res.data.data))
              fetchAvailablities(tempUserDetails.id)
            }
          }).catch((err) => {
            console.log(err)
            setGetTimingErr(err.response.data.message)
            setShowLoader(false)
          })
        }
      }
    } else {
      navigate(routes.card)
    }
  }, [navigate])

  useEffect(() => {
    let tempAllStrings = sessionStorage.getItem("allStrings")
    if(tempAllStrings) {
      tempAllStrings = JSON.parse(tempAllStrings);
      setAllString(tempAllStrings)
    }
  }, [sessionStoreAllString])

  const handleDateRangeModalShow = () => {
    setShowSlots(false)
    setSlotIds([])
    const tempModal = new window.bootstrap.Modal(dateSelectionModalRef.current);
    setDateSelectionModal(tempModal);
    tempModal.show();
    var allElements = document.getElementsByTagName("*");
    setTimeout(() => {
      for (var i = 0; i < allElements.length; i++) {
        let targetText = "MUI X Missing license key";
        if (allElements[i].innerText === targetText) {
          allElements[i].remove()
        }
      }
    },100)
  }

  const handleDateRangeSubmit = () => {
    if(dateRangeVal[0] && dateRangeVal[1]){
      let startDate=dayjs(dateRangeVal[0]).format("YYYY-MM-DD")
      let endDate=dayjs(dateRangeVal[1]).format("YYYY-MM-DD")
      let daysOfRange = []
      for (let d = new Date(startDate); d <= new Date(endDate);  d.setDate(d.getDate() + 1)) {
        let dateToInsert=dayjs(new Date(d)).format("YYYY-MM-DD")
        daysOfRange.push({date: dateToInsert});
      }
      setAvailableSlotsRange(daysOfRange)
      if(daysOfRange.length){
        setShowSlots(true)
      }
      setDateRangeVal(["", ""])
    } else {
      setShowSelectionErr(true)
      setTimeout(() => {
        setShowSelectionErr(false)
      }, 3000)
    }
  }

  const shouldDisableDate = (day) => {
    // Define your logic to disable specific dates
    // For example, disable all weekends
    let disabledDates=selectedDateAndTimeSlots.map((item) => item.date)
    return (disabledDates.includes(dayjs(day).format("YYYY-MM-DD")))
  };

  const handleTimeSlotSelect = (id) => {
    let tempIds = slotIds;
    if(tempIds.includes(id)) {
      tempIds = tempIds.filter((item) => {
        if(item === id) {
          return false
        } else {
          return true
        }
      })
    } else {
      tempIds = [...tempIds, id]
    }
    tempIds.sort((a, b) => a - b)
    setSlotIds(tempIds)
  }

  const handleSlotsSubmit = () => {
    let tempData = availableSlotsRange
    if(slotIds.length) {
      let selectedSlots = timeSlots.filter((item) => {
        if(slotIds.includes(item.id)){
          return true
        } else {
          return false
        }
      })
      let startDate = tempData[0].date
      let endDate = tempData[tempData.length-1].date
      let timeIds = selectedSlots.map((item) => item.id).join(",")
      let data = {
        user_id: userDetails.id,
        from_date: startDate,
        to_date: endDate,
        time_ids: timeIds
      }
      setShowLoader(true)
      axios.post(baseURL + relativeURL.addUpdateAvailability, data).then((res) => {
        setShowLoader(false)
        if(res.data.success) {
          setDateRangeVal(["", ""])
          fetchAvailablities(userDetails.id)
        }
      }).catch(err => {
        console.log(err)
        setShowLoader(false)
      })
      dateSelectionModal.hide();
    } else {
      setShowSelectionErr(true)
      setTimeout(() => {
        setShowSelectionErr(false)
      }, 3000)
    }
  }

  const fetchAvailablities = (id) => {
    let data = { user_id: id }
    setShowLoader(true)
    axios.post(baseURL + relativeURL.escortAvailabilityList, data).then(res => {
      setShowLoader(false)
      console.log()
      if(res.data.success) {
        setSelectedDateAndTimeSlots(res.data.data);
      }
    }).catch((err) => {
      setShowLoader(false)
      setFetchAvlErr(err.response.data.message)
      console.log(err)
    })
  }

  const handleDateEdit = (e, index) => {
    e.stopPropagation();
    setSlotIds([])
    const tempModal = new window.bootstrap.Modal(slotsEditModalRef.current);
    setSlotsEditModal(tempModal);
    tempModal.show();
    let tempSelectedIds = selectedDateAndTimeSlots[index].slots.map((item) => item.id)
    setSlotIds(tempSelectedIds)
    setEditIndex(index)
  }
  const handleEditSlotsSubmit = () => {
    if(slotIds.length) {
      let selectedSlots = timeSlots.filter((item) => {
        if(slotIds.includes(item.id)){
          return true
        } else {
          return false
        }
      })
      let startDate = selectedDateAndTimeSlots[editIndex].date
      let endDate = selectedDateAndTimeSlots[editIndex].date
      let timeIds = selectedSlots.map((item) => item.id).join(",")
      let data = {
        user_id: userDetails.id,
        from_date: startDate,
        to_date: endDate,
        time_ids: timeIds
      }
      setShowLoader(true)
      axios.post(baseURL + relativeURL.addUpdateAvailability, data).then((res) => {
        setShowLoader(false)
        if(res.data.success) {
          setDateRangeVal(["", ""])
          fetchAvailablities(userDetails.id)
          slotsEditModal.hide()
        }
      }).catch(err => {
        console.log(err)
        setShowLoader(false)
      })
    } else {
      setShowSelectionErr(true);
      setTimeout(() => {
        setShowSelectionErr(false);
      }, 3000)
    }
  }
  const handleDateDelete = (e, index) => {
    e.stopPropagation();
    const tempModal = new window.bootstrap.Modal(confirmDeleteDateRef.current);
    setDateDeleteModal(tempModal);
    tempModal.show();
    setEditIndex(index)
  }
  const handleDeletePopUpBox = () => {
    let data = { user_id: userDetails.id, available_date: selectedDateAndTimeSlots[editIndex].date }
    setShowLoader(true)
    axios.post(baseURL + relativeURL.escortAvailabilityDelete, data).then((res) => {
      setShowLoader(false)
      fetchAvailablities(userDetails.id);
      dateDeleteModal.hide()
    }).catch((err) => {
      console.log(err)
      setShowLoader(false)
    })
  }
  const convert24HrTo12Hr = (val) => {
    let date = new Date();
    date.setHours(val.split(":")[0])
    date.setMinutes(val.split(":")[1])
    return dayjs(date, { hour: 'HH', minute: 'mm' }).format('hh:mm A')
  }

  // const getLocation = () => {
  //   const successCallback = (position) => {
  //     let data = {
  //       user_id: userDetails.id,
  //       latitude: position.coords.latitude,
  //       longitude: position.coords.longitude
  //     }
  //     let flag = false
  //     if(sessionStorage.getItem("escortLocationTaken")) {
  //       flag = JSON.parse(sessionStorage.getItem("escortLocationTaken"))
  //     }
  //     if(flag===false) {
  //       setShowLoader(true)
  //       axios.post(baseURL + relativeURL.updateLocation, data).then((res) => {
  //         setShowLoader(false)
  //         console.log(res)
  //         sessionStorage.setItem("escortLocationTaken", JSON.stringify(true))
  //       }).catch((err) => {
  //         console.log(err)
  //         setShowLoader(false)
  //         sessionStorage.setItem("escortLocationTaken", JSON.stringify(false))
  //       })
  //     }
  //   };
    
  //   const errorCallback = (error) => {
  //     // console.log(error);
  //   };
    
  //   let options = { enableHighAccuracy: true, maximumAge: 5000 }
  //   return navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
  // }

  const checkLocationPermissionForAdd = () => {
    handleDateRangeModalShow()
    // if (navigator.geolocation) {
    //   navigator.permissions.query({ name: "geolocation" }).then((result) => {
    //     if (result.state === "granted") {
    //       getLocation()
    //       handleDateRangeModalShow()
    //     } else if (result.state === "prompt") {
    //       getLocation()
    //       handleDateRangeModalShow()
    //     } else if (result.state === "denied") {
    //       if (enableLocationRef.current) {
    //         const modal = new window.bootstrap.Modal(enableLocationRef.current);
    //         setEnableLocationModal(modal);
    //         modal.show();
    //       }
    //     }
    //   }).catch(err => console.log(err));
    // } else {
    //   console.log("Geolocation is not supported by this browser.");
    // }
  }

  const checkLocationPermissionForEditDelete = (e, index, type) => {
    if(type === "edit") {
      handleDateEdit(e, index)
    } else if(type === "delete") {
      handleDateDelete(e, index)
    }
    // if (navigator.geolocation) {
    //   navigator.permissions.query({ name: "geolocation" }).then((result) => {
    //     if (result.state === "granted") {
    //       getLocation()
    //       if(type === "edit") {
    //         handleDateEdit(e, index)
    //       } else if(type === "delete") {
    //         handleDateDelete(e, index)
    //       }
    //     } else if (result.state === "prompt") {
    //       getLocation()
    //       if(type === "edit") {
    //         handleDateEdit(e, index)
    //       } else if(type === "delete") {
    //         handleDateDelete(e, index)
    //       }
    //     } else if (result.state === "denied") {
    //       if (enableLocationRef.current) {
    //         const modal = new window.bootstrap.Modal(enableLocationRef.current);
    //         setEnableLocationModal(modal);
    //         modal.show();
    //       }
    //     }
    //   }).catch(err => console.log(err));
    // } else {
    //   console.log("Geolocation is not supported by this browser.");
    // }
  }

  return (
    <>
      <Loader show={showLoader} />
      <div className='dateRangePickerContainer d-flex align-items-center justify-content-center p-3'>
        <div className='dateRangePicker w-100 p-0'>
          <div className='manageAvailablityHeading d-flex w-100 justify-content-center align-items-center position-relative mb-4'>
            <button className='backBtn my-2 border-0' onClick={() => navigate(routes.card)}>
              <img src={leftArrow} alt='' />
            </button>
            <div className='manageAvailablityHeadingTitle text-primary d-flex'>{allStrings.manage_availability ? allStrings.manage_availability : "Manage Availability"}</div>
          </div>
          <div className='addDateRangeBtn p-2 d-flex align-items-center border-primary text-primary w-100 d-flex align-items-center justify-content-center' onClick={checkLocationPermissionForAdd}>{allStrings.add ? allStrings.add : "Add"}</div>
          <div className="accordion mt-4" id="accordionExample">
            { selectedDateAndTimeSlots.length
              ? selectedDateAndTimeSlots?.map((item, index) => {
                  return(
                    <div className="accordion-item" key={`accordianItem_${index}`}>
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button collapsed justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_${index}`}>
                          {dayjs(item.date).format("DD-MM-YYYY")}
                          <div className='d-flex justify-content-end align-items-center'>
                            <div className='me-2 d-flex justify-content-center align-items-center' onClick={(e) => checkLocationPermissionForEditDelete(e, index, "edit")}>
                              <img src={editIcon} alt='' />
                            </div>
                            <div className='ms-2' onClick={(e) => checkLocationPermissionForEditDelete(e, index, "delete")}>
                              <img height={18} width={18} src={deleteIcon} alt='' />
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div id={`collapse_${index}`} className="accordion-collapse collapse">
                        <div className="accordion-body p-2">
                        {item.slots?.map((slot, idx) => {
                          return(
                            <div className='slotItem mb-1 d-flex justify-content-between align-items-center text-white bg-primary px-2 py-1' key={`slot_${idx}`}>
                              <div>{convert24HrTo12Hr(slot.from_time) + "-" + convert24HrTo12Hr(slot.to_time)}</div>
                              {/* <div onClick={handleTimeSlotDelete}>Delete</div> */}
                            </div>
                          )
                        })}
                        </div>
                      </div>
                    </div>
                  )
                })
              : <div className='w-100 text-center fw-bold'>
                  {fetchAvlErr ? fetchAvlErr : "Data Not Found!"}
                </div>
            }
          </div>
          <div className="modal fade" id="congratulationsModal" ref={dateSelectionModalRef}>
            <div className="modal-dialog modal-dialog-centered d-flex justify-content-center align-items-center">
              <div className="modal-content position-relative border-0 dateRangePickerModal d-flex align-items-center justify-content-center p-2">
                <div className={`d-flex justify-content-${showSlots ? 'between' : 'end'} align-items-center w-100`}>
                  {showSlots
                    ? <div className='text-primary ms-2 editTimePopUpLabel'>
                        {availableSlotsRange[0]?.date === availableSlotsRange[availableSlotsRange.length-1]?.date
                          ? <>
                              {`${dayjs(availableSlotsRange[0]?.date)?.format("DD/MM/YYYY")}`}
                            </>
                          : <>
                              {`${dayjs(availableSlotsRange[0]?.date)?.format("DD/MM/YYYY")} - ${dayjs(availableSlotsRange[availableSlotsRange.length-1]?.date)?.format("DD/MM/YYYY")}`}
                            </>
                        }
                      </div>
                    : <></>
                  }
                  <button className={`border-0 bg-none d-flex justify-content-center align-items-center dateRangeModalCloseIcon ${showSlots ? "position-relative top-0 end-0 pe-1 mb-2" : ""}`} onClick={() => {dateSelectionModal.hide()}}>
                    <img src={closeImg} alt='' />
                  </button>
                </div>
                { showSlots
                  ? <>
                      <div className='row w-100 mb-2'>
                        {timeSlots.map((item, index) => {
                          return (
                            <div key={index} className='col-6 p-1 d-flex justify-content-center align-items-center'>
                              <button className={`btn w-100 h-100 ${( slotIds.includes(item.id) ? 'bg-primary text-white' : 'btn-outline-secondary')}`} onClick={() => handleTimeSlotSelect(item.id)}>
                                { convert24HrTo12Hr(item.from_time) + "-" + convert24HrTo12Hr(item.to_time) }
                              </button>
                            </div>
                          )
                        })}
                      </div>
                      { showSelectionErr
                        ? <div className='text-danger'>{allStrings.please_select_time_slot ? allStrings.please_select_time_slot : "Please Select Time Slot"}</div>
                        : <></>
                      }
                      { timeSlots.length
                        ? <div className='d-flex w-100 justify-content-end mb-3 me-4'>
                            <button className='px-3 bg-primary DateTimeSubmitBtn border-0 text-white' onClick={handleSlotsSubmit}>
                              {Object.keys(allStrings).length ? allStrings.submit : ""}
                            </button>
                          </div>
                        : <div className='w-100 text-center fw-bold'>
                            {getTimingErr}
                          </div>
                      }
                    </>
                  : <>
                      <StyledEngineProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StaticDateRangePicker showDaysOutsideCurrentMonth={true} orientation="portrait" minDate={dayjs(new Date().setDate(new Date().getDate() + 1))} value={dateRangeVal} onChange={(val) => setDateRangeVal(val)} shouldDisableDate={shouldDisableDate} 
                          slotProps={{
                            shortcuts: {
                              items: shortcutsItems,
                            },
                            actionBar: { actions: [] },
                          }} />
                        </LocalizationProvider>
                      </StyledEngineProvider>
                      { showSelectionErr
                        ? <>
                            {(dateRangeVal[0]) && (!dateRangeVal[1])
                              ? <div className='text-danger'>{allStrings.please_select_end_date ? allStrings.please_select_end_date : "Please Select End Date"}</div>
                              : <div className='text-danger'>{allStrings.please_select_date ? allStrings.please_select_date : "Please Select Date"}</div>
                            }
                          </>
                        : <></>
                      }
                      <div className='d-flex w-100 justify-content-end mb-3 me-4 mt-2'>
                        <button className='px-3 bg-primary DateTimeSubmitBtn border-0 text-white' onClick={handleDateRangeSubmit}>
                          {Object.keys(allStrings).length ? allStrings.submit : ""}
                        </button>
                      </div>
                    </>
                }
              </div>
            </div>
          </div>
          <div className="modal fade" id="congratulationsModal" ref={slotsEditModalRef}>
            <div className="modal-dialog modal-dialog-centered d-flex justify-content-center align-items-center">
              <div className="modal-content position-relative border-0 dateRangePickerModal d-flex align-items-center justify-content-center p-3 px-2">
                <div className='d-flex justify-content-between align-items-center w-100'>
                  <div className='text-primary ms-2 editTimePopUpLabel'> {`Date : ${dayjs(selectedDateAndTimeSlots[editIndex]?.date)?.format("DD-MM-YYYY")}`}</div>
                  <button className={`border-0 bg-none d-flex justify-content-center align-items-center dateRangeModalCloseIcon pe-1 mb-2 position-relative top-0 end-0`} onClick={() => {slotsEditModal.hide()}}>
                    <img src={closeImg} alt='' />
                  </button>
                </div>
                <div className='row w-100 mb-2'>
                  {timeSlots.map((item, index) => {
                    return (
                      <div key={index} className='col-6 p-1 d-flex justify-content-center align-items-center'>
                        <button className={`btn w-100 h-100 ${( slotIds.includes(item.id) ? 'bg-primary text-white' : 'btn-outline-secondary')}`} onClick={() => handleTimeSlotSelect(item.id)}>
                          { convert24HrTo12Hr(item.from_time) + "-" + convert24HrTo12Hr(item.to_time) }
                        </button>
                      </div>
                    )
                  })}
                </div>
                { showSelectionErr
                  ? <div className='text-danger'>{allStrings.please_select_time_slot ? allStrings.please_select_time_slot : "Please Select Time Slot"}</div>
                  : <></>
                }
                { timeSlots.length
                  ? <div className='d-flex w-100 justify-content-end mb-3 me-2'>
                      <button className='px-3 bg-primary DateTimeSubmitBtn border-0 text-white' onClick={handleEditSlotsSubmit}>
                        {Object.keys(allStrings).length ? allStrings.submit : ""}
                      </button>
                    </div>
                  : <></>
                }
              </div>
            </div>
          </div>
          <div className="modal fade" id="" ref={confirmDeleteDateRef}>
            <div className="modal-dialog modal-dialog-centered d-flex justify-content-center align-items-center">
              <div className="modal-content deleteDateAvlBox border-0">
                <div className="modal-body d-flex justify-content-between align-items-center flex-column p-4">
                  <img fill={"#FF2121"} height={75} width={75} src={dangerIcon} alt='' />
                  {/* <div className='d-flex justify-content-start align-items-center flex-column'> */}
                    <div className='deleteDateAvlBoxText text-center my-2'>{allStrings.are_you_sure ? allStrings.are_you_sure : "Are you sure?"}</div>
                    <div className='deleteDateAvlBoxDesc text-center mb-3'>{`Remove Date : ${dayjs(selectedDateAndTimeSlots[editIndex]?.date)?.format("DD-MM-YYYY")}`}.</div>
                  {/* </div> */}
                  <div className='d-flex justify-content-center w-100'>
                    <button className='border-0 text-white bg-primary mx-2' onClick={handleDeletePopUpBox}>{allStrings.yes ? allStrings.yes : "Yes"}</button>
                    <button className='bg-none border-0 text-primary border-primary mx-1' onClick={() => dateDeleteModal.hide()}>
                      {allStrings.no ? allStrings.no : "No"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="modal fade" id="" ref={enableLocationRef}>
            <div className="modal-dialog modal-dialog-centered d-flex justify-content-center align-items-center">
              <div className="modal-content enableLocationModalBox border-0">
                <div className="modal-body d-flex justify-content-between align-items-center flex-column p-4">
                  <img height={165} src={siteSettingsIcon} alt='' />
                  <div className='enableLocationModalBoxDesc text-center mb-3 mt-2'>
                    {allStrings.click_the_lock_icon_in_your_browser_s_address_bar___turn_on_location 
                      ? allStrings.click_the_lock_icon_in_your_browser_s_address_bar___turn_on_location
                      : "Click the lock icon in your browser's address bar & Turn on location."
                    } 
                  </div>
                  <div className='d-flex justify-content-center w-100'>
                    <button className='border-0 text-white bg-primary mx-2' onClick={() => enableLocationModal.hide()}>{allStrings.ok ? allStrings.ok : "OK"}</button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default ManageAvailablity