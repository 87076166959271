import React from 'react'
import "./MyBookings.css"
import leftArrow from "../images/arrow-left.svg"
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../Routes'
import Loader from './Loader'
import { useEffect } from 'react'
import axios from 'axios'
import { baseURL, relativeURL } from '../APIS'
import dayjs from 'dayjs'

const MyBookings = () => {
  const navigate = useNavigate()
  const [showLoader, setShowLoader] = useState(false);
  const [userBookings, setUserBookings] = useState({});
  const [isClient, setIsClient] = useState(false)
  const [isEscort, setIsEscort] = useState(false)
  const [userDetails, setUserDetails] = useState({})
  const [myBookingsFilter, setMyBookingsFilter] = useState("all")
  const [allStrings, setAllString] = useState({})
  const sessionStoreAllString = sessionStorage.getItem("allStrings");

  useEffect(() => {
    let tempUserDetails = sessionStorage.getItem("userVerified")
    if(tempUserDetails) {
      tempUserDetails = JSON.parse(tempUserDetails);
      setUserDetails(tempUserDetails)
      let tempUpcomingBooking = sessionStorage.getItem("upcomingBooking")
      let upcomingFlag = false;
      if(tempUpcomingBooking) {
        tempUpcomingBooking = JSON.parse(tempUpcomingBooking)
        if(tempUpcomingBooking === true) {
          upcomingFlag = true
        }
      } 
      if(upcomingFlag) {
        handleMyBookingsFilter(tempUserDetails, "upcoming")
      } else {
        handleMyBookingsFilter(tempUserDetails, "all")
      }
    } else {
      navigate(routes.card)
    }
  }, [navigate])

  useEffect(() => {
    let tempAllStrings = sessionStorage.getItem("allStrings")
    if(tempAllStrings) {
      tempAllStrings = JSON.parse(tempAllStrings);
      setAllString(tempAllStrings)
    }
  }, [sessionStoreAllString])

  const handleMyBookingsFilter = (tempUserDetails, filter) => {
    setMyBookingsFilter(filter)
    let data = {
      escort_id: "",
      user_id: "",
      filter: filter
    };
    if(tempUserDetails.user_role==="client") {
      data.user_id = tempUserDetails.id
    } else {
      data.escort_id = tempUserDetails.id
    }
    setShowLoader(true)
    axios.post(baseURL + relativeURL.bookingList, data).then((res) => {
      setShowLoader(false)
      if(res.data.success) {
        let tempData = res.data.data
        setUserBookings(tempData);
        if(tempUserDetails.user_role==="client"){
          setIsClient(true)
          setIsEscort(false)
          // let escortsData = tempData.map((item) => item.getescorts)
          // setUserBookings(escortsData);
        } else {
          setIsClient(false)
          setIsEscort(true)
          // let clientsData = tempData.map((item) => item.getusers)
          // setUserBookings(clientsData);
        }
      }
    }).catch((err) => {
      setShowLoader(false)
      console.log(err)
    })
  }

  const handleMyBoookingsFilterChange = (filter) => {
    handleMyBookingsFilter(userDetails, filter)
  }

  const handleBackBtn = () => {
    let tempUpcomingBooking = sessionStorage.getItem("upcomingBooking")
    if(tempUpcomingBooking) {
      sessionStorage.removeItem("upcomingBooking")
    }
    navigate(routes.card)
  }

  return (
    <>
      <div className='myBookingsContainer p-3 d-flex justify-content-start align-items-center flex-column'>
        <Loader show={showLoader} />
        <div className='myBookingsHeading d-flex w-100 justify-content-center align-items-center position-relative'>
          <button className='backBtn my-2 border-0' onClick={() => handleBackBtn()}>
            <img src={leftArrow} alt='' />
          </button>
          <div className='myBookingsHeadingTitle text-primary d-flex'>{allStrings.my_bookings ? allStrings.my_bookings : "My Bookings"}</div>
        </div>
        <div className='d-flex align-items-center justify-content-center'>
          <button className={'myBoookingsFilterBtn p-2 mx-2 my-3 d-flex align-iems-center justify-content-center border-0'+(myBookingsFilter==="all" ? " text-white bg-primary" : " text-primary bg-secondary")} onClick={() => handleMyBoookingsFilterChange("all")}>{allStrings.all ? allStrings.all : "All"}</button>
          <button className={'myBoookingsFilterBtn p-2 mx-2 my-3 d-flex align-iems-center justify-content-center border-0'+(myBookingsFilter==="past" ? " text-white bg-primary" : " text-primary bg-secondary")} onClick={() => handleMyBoookingsFilterChange("past")}>{allStrings.past ? allStrings.past : "Past"}</button>
          <button className={'myBoookingsFilterBtn p-2 mx-2 my-3 d-flex align-iems-center justify-content-center border-0'+(myBookingsFilter==="upcoming" ? " text-white bg-primary" : " text-primary bg-secondary")} onClick={() => handleMyBoookingsFilterChange("upcoming")}>{allStrings.upcoming ? allStrings.upcoming : "Upcoming"}</button>
        </div>
        { userBookings.length
          ? <>
              { userBookings?.map((item, index) => {
                return(
                  <React.Fragment key={index}>
                    { isEscort
                      ? <div key={index} className='bookingListCard w-100 p-3 m-2'>
                          { item.getusers.mobile_no 
                            ? <div className='d-flex align-items-start mb-2'>
                                <div className='bookingListKey'> {allStrings.mobile_number ? allStrings.mobile_number : "Mobile Number"}: </div>
                                <div className='bookingListVal text-primary ms-1'>{item.getusers.phone_code ? (item.getusers.phone_code) : " "}{item.getusers.mobile_no}</div>
                              </div>
                            : <></>
                          }
                          { item.hotel_name 
                            ? <div className='d-flex align-items-start mb-2'>
                                <div className='bookingListKey'> {allStrings.hotel ? allStrings.hotel : "Hotel"}: </div>
                                <div className='bookingListVal text-primary ms-1'>{item.hotel_name}</div>
                              </div>
                            : <></>
                          }
                          { item.selected_word
                            ? <div className='d-flex align-items-start mb-2'>
                                <div className='bookingListKey'> {allStrings.ward ? allStrings.ward.split("*")[0] : "Ward"}: </div>
                                <div className='bookingListVal text-primary ms-1'>{item.selected_word}</div>
                              </div>
                            : <></>
                          }
                          { item.booking_price
                            ? <div className='d-flex align-items-start'>
                                <div className='bookingListKey'> {allStrings.booking_price ? allStrings.booking_price : "Booking Price"}: </div>
                                <div className='bookingListVal text-primary ms-1'>{item.booking_price}</div>
                              </div>
                            : <></>
                          }
                          { item.booking_slots.length
                            ? <>
                                <div className='d-flex align-items-start my-2'>
                                  <div className='bookingListKey'> {allStrings.date ? allStrings.date : "Date"}: </div>
                                  <div className='bookingListVal text-primary ms-1'>{dayjs(item.booking_slots[0].booking_date).format("DD-MM-YYYY")}</div>
                                </div>
                                <div className='d-flex align-items-start'>
                                  <div className='bookingListKey'> {allStrings.timing ? allStrings.timing : "Timing"}: </div>
                                  <div className='bookingListVal text-primary ms-1'>{item.booking_slots[0].from_time + " - " + item.booking_slots[item.booking_slots.length-1].to_time}</div>
                                </div>
                              </>
                            : <></>
                          }
                        </div>
                      : <></>
                    }
                    { isClient
                      ? <div key={index} className='bookingListCard w-100 p-3 m-2'>
                          { item.getescorts.name 
                            ? <div className='d-flex align-items-start mb-2'>
                                <div className='bookingListKey'> {allStrings.name ? allStrings.name : "Name"}: </div>
                                <div className='bookingListVal text-primary ms-1'>{item.getescorts.name}</div>
                              </div>
                            : <></>
                          }
                          { item.getescorts.mobile_no 
                            ? <div className='d-flex align-items-start mb-2'>
                                <div className='bookingListKey'> {allStrings.mobile_number ? allStrings.mobile_number : "Mobile Number"}: </div>
                                <div className='bookingListVal text-primary ms-1'>{item.getescorts.phone_code ? (item.getescorts.phone_code+" ") : ""}{item.getescorts.mobile_no}</div>
                              </div>
                            : <></>
                          }
                          { item.hotel_name 
                            ? <div className='d-flex align-items-start mb-2'>
                                <div className='bookingListKey'> {allStrings.hotel ? allStrings.hotel : "Hotel"}: </div>
                                <div className='bookingListVal text-primary ms-1'>{item.hotel_name}</div>
                              </div>
                            : <></>
                          }
                          { item.selected_word
                            ? <div className='d-flex align-items-start mb-2'>
                                <div className='bookingListKey'> {allStrings.ward ? allStrings.ward.split("*")[0] : "Ward"}: </div>
                                <div className='bookingListVal text-primary ms-1'>{item.selected_word}</div>
                              </div>
                            : <></>
                          }
                          { item.booking_price
                            ? <div className='d-flex align-items-start'>
                                <div className='bookingListKey'> {allStrings.booking_price ? allStrings.booking_price : "Booking Price"}: </div>
                                <div className='bookingListVal text-primary ms-1'>{item.booking_price}</div>
                              </div>
                            : <></>
                          }
                          { item.booking_slots.length
                            ? <>
                                <div className='d-flex align-items-start my-2'>
                                  <div className='bookingListKey'> {allStrings.date ? allStrings.date : "Date"}: </div>
                                  <div className='bookingListVal text-primary ms-1'>{dayjs(item.booking_slots[0].booking_date).format("DD-MM-YYYY")}</div>
                                </div>
                                <div className='d-flex align-items-start'>
                                  <div className='bookingListKey'> {allStrings.timing ? allStrings.timing : "Timing"}: </div>
                                  <div className='bookingListVal text-primary ms-1'>{item.booking_slots[0].from_time + " - " + item.booking_slots[item.booking_slots.length-1].to_time}</div>
                                </div>
                              </>
                            : <></>
                          }
                        </div>
                      : <></>
                    }
                  </React.Fragment>
                )
              })}
            </>
          : <>
              {myBookingsFilter==="upcoming"
                ? <div className='w-100 d-flex justify-content-center align-items-center text-primary fw-bold'>{allStrings.no_upcoming_bookings ? allStrings.no_upcoming_bookings : "No upcoming bookings!"}</div>
                : <>
                    {myBookingsFilter==="past"
                      ? <div className='w-100 d-flex justify-content-center align-items-center text-primary fw-bold'>{allStrings.no_past_bookings ? allStrings.no_past_bookings : "No past bookings!"}</div>
                      : <div className='w-100 d-flex justify-content-center align-items-center text-primary fw-bold'>{allStrings.bookings_not_found ? allStrings.bookings_not_found : "Bookings not Found !"}</div>
                    }
                  </>
              }     
            </>
        }
      </div>
    </>
  )
}

export default MyBookings