import React, { useEffect, useState, useRef } from 'react'
import leftArrow from "../images/arrow-left.svg"
// import escortImg from "../images/f35c2f24a45b0a139c659daa592b39a5.png";
import downArrow from "../images/chevron-down.svg"
import "./BookingPage.css";
import { useNavigate } from 'react-router-dom';
import { routes } from '../Routes';
import congratsImg from "../images/Group 427318799.svg"
import loaderImg from "../images/Mask group.svg"
import DateTimePicker from './DateTimePicker';
import axios from 'axios';
import Loader from './Loader';
import { baseURL, relativeURL } from '../APIS';
// import siteSettingsIcon from "../images/Site Settings.svg"
import dayjs from 'dayjs';

const BookingPage = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState(dayjs(new Date()).format("YYYY-MM-DD"))
  const [bookingHrs, setBookingHrs] = useState(1)
  const [character, setCharacter] = useState({})
  const [selectedWard, setSelectedWard] = useState("")
  const [hotelAddress, setHotelAddress] = useState("")
  // const [roomNumber, setRoomNumber] = useState("")
  const [slotIds, setSlotIds] = useState("")
  const [startTime, setStartTime] = useState("")
  const [endTime, setEndTime] = useState("")
  const [showBookingFormError, setShowBookingFormError] = useState(false);
  const [showLoader, setShowLoader] = useState(false)
  const [todaysSlots, setTodaysSlots] = useState([])
  const [showBookNow, setShowBookNow] = useState(false)
  const [showOtherBookingDetails, setShowOtherBookingDetails] = useState(true)
  const [backBtnRoute, setBackBtnRoute] = useState("");
  const [allStrings, setAllString] = useState({})
  const sessionStoreAllString = sessionStorage.getItem("allStrings");
  const wardOptions = ["Minato", "Chiyoda", "Chūō", "Shinjuku", "Bunkyō", "Taitō", "Sumida", "Kōtō", "Shinagawa", "Meguro", "Ōta", "Setagaya", "Shibuya", "Nakano", "Suginami", "Toshima", "Kita", "Arakawa", "Itabashi", "Nerima", "Adachi", "Katsushika", "Edogawa"];
  const congratsModalRef = useRef(null);
  // const enableLocationRef = useRef(null);
  // const [enableLocationModal, setEnableLocationModal] = useState();

  useEffect(() => {
    let tempAllStrings = sessionStorage.getItem("allStrings")
    if(tempAllStrings) {
      tempAllStrings = JSON.parse(tempAllStrings);
      setAllString(tempAllStrings)
    }
  }, [sessionStoreAllString])

  const bookNowApiCall = (hr) => {
    let escort = sessionStorage.getItem("escortBooking")
    escort = JSON.parse(escort)
    let currentDate = new Date()
    let todayDate = dayjs(currentDate).format('YYYY-MM-DD')
    const setInitialDateTime = () => {
      // console.log(todayDate)
      let data = {
        escort_id: escort.id,
        selected_date: todayDate,
        slot_duration: hr
      }
      setShowLoader(true);
      axios.post(baseURL + relativeURL.escortsDateWiseAvl, data).then(res => {
        setShowLoader(false);
        if (res.data.success) {
          let tempData = res.data.data
          setTodaysSlots(tempData)
          if (tempData.length) {
            let actualTime = dayjs(new Date().setHours(new Date().getHours() + 1)).format('HH:MM')
            let tempSlots = []
            for (let i = 0; i < tempData.length; i++) {
              if (tempData[i].start_time > actualTime) {
                tempSlots.push(tempData[i])
              }
            }
            if (tempSlots.length) {
              setTodaysSlots(tempSlots)
              setSlotIds(tempSlots[0].id)
              setShowOtherBookingDetails(true)
            } else {
              setTodaysSlots(tempSlots)
              setShowOtherBookingDetails(false)
            }
          } else {
            setShowOtherBookingDetails(false)
          }
        }
      }).catch(err => {
        setShowLoader(false);
        console.log(err)
      })
    }
    setInitialDateTime()
  }
  useEffect(() => {
    let escort = sessionStorage.getItem("escortBooking")
    if (escort) {
      setBackBtnRoute(sessionStorage.getItem("forBookingPage"))
      escort = JSON.parse(escort)
      setCharacter(escort)
      let tempBookNow = sessionStorage.getItem("showBookNow")
      setShowBookNow(JSON.parse(tempBookNow))
      if (JSON.parse(tempBookNow)) {
        bookNowApiCall(1);
      } else {
        setDate("")
        setTodaysSlots([])
      }
    } else {
      navigate(routes.card)
    }
  }, [navigate])

  const openCongratsModal = () => {
    if (congratsModalRef.current) {
      const modal = new window.bootstrap.Modal(congratsModalRef.current);
      modal.show();
      setTimeout(() => {
        modal.hide();
        navigate(routes.bookingConfirmed)
      }, 5000)
    }
  };

  const setDateValues = (tempDate) => {
    // parsing object to string
    let formatedDateTime = tempDate.format('YYYY-MM-DD');
    if(date !== formatedDateTime){
      setSlotIds("")
    }
    // setStartTime(obj.startTime)
    // setEndTime(obj.endTime)
    setDate(formatedDateTime);
  }

  // const getLocation = () => {
  //   const successCallback = (position) => {
  //     let temp = {latitude: position.coords.latitude, longitude: position.coords.longitude}
  //     sessionStorage.setItem("clientLatLon", JSON.stringify(temp))
  //     let data = {
  //       user_id: character.id,
  //       latitude: position.coords.latitude,
  //       longitude: position.coords.longitude
  //     }
  //     setShowLoader(true)
  //     axios.post(baseURL + relativeURL.updateLocation, data).then((res) => {
  //       setShowLoader(false)
  //       // console.log(res)
  //       bookOrder()
  //     }).catch((err) => {
  //       console.log(err)
  //       setShowLoader(false)
  //     })
  //   };
    
  //   const errorCallback = (error) => {
  //     // console.log(error);
  //   };
    
  //   let options = { enableHighAccuracy: true, maximumAge: 5000 }
  //   navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
  // }

  const handleBookingDetailSubmit = () => {
    bookOrder()
    // if (navigator.geolocation) {
    //   navigator.permissions.query({ name: "geolocation" }).then((result) => {
    //     if (result.state === "granted") {
    //       getLocation()
    //     } else if (result.state === "prompt") {
    //       getLocation()
    //     } else if (result.state === "denied") {
    //       if (enableLocationRef.current) {
    //         const modal = new window.bootstrap.Modal(enableLocationRef.current);
    //         setEnableLocationModal(modal);
    //         modal.show();
    //       }
    //     }
    //   }).catch(err => console.log(err));
    // } else {
    //   console.log("Geolocation is not supported by this browser.");
    // }
  }

  const bookOrder = () => {
    if (date && slotIds && hotelAddress && selectedWard && bookingHrs) {
      let escortDetail = JSON.parse(sessionStorage.getItem("escortBooking"))
      let userDetail = JSON.parse(sessionStorage.getItem("userVerified"))
      let tempObj = {
        escort_id: String(escortDetail.id),
        user_id: String(userDetail.id),
        hotel_name: hotelAddress,
        selected_word: selectedWard,
        booking_date: date,
        slot_ids: String(slotIds),
        slot_duration: String(bookingHrs)
      }
      sessionStorage.setItem("bookingDetails", JSON.stringify(tempObj))
      setShowLoader(true)
      axios.post(baseURL + relativeURL.escortsBooking, tempObj).then((res) => {
        setShowLoader(false)
        openCongratsModal()
        // console.log(res)
      }).catch(err => {
        setShowLoader(false)
        console.log(err)
      })
    } else {
      setShowBookingFormError(true)
      setTimeout(() => {
        setShowBookingFormError(false)
      }, 3000)
    }
  }

  const handleButtonClick = (item) => {
    setSlotIds(item.id)
    setStartTime(item.start_time)
    setEndTime(item.end_time)
  }

  return (
    <div className='bookingPageContainer p-3 d-flex justify-content-start align-items-center flex-column'>
      <Loader show={showLoader} />
      <div className='bookingPageHeading d-flex w-100 justify-content-center align-items-center position-relative mb-4'>
        <button className='backBtn my-2 border-0' onClick={() => navigate(backBtnRoute)}>
          <img src={leftArrow} alt='' />
        </button>
        <div className='bookingPageHeadingTitle text-primary d-flex'>{Object.keys(allStrings).length ? allStrings.submit_your_booking : ""}</div>
      </div>
      <div className='card m-0 border-0 justify-content-between' style={{ backgroundImage: 'url(' + (Object.keys(character).length ? character.escort_images[0].file_path : "") + ')' }}>
        <div className='mt-2 mx-2 d-flex justify-content-center imageCounterContainer'>
        </div>
        <div className='cardInfoContainer d-flex align-items-end h-50 p-2'>
          <div className='w-100 justify-content-between align-items-center d-flex px-2 mb-2'>
            <div>
              <div className='heading_h3 text-white'>
                {Object.keys(character).length ? (character.name + ", " + character.age) : ""}
              </div>
              {/* <div className='escortNearBy d-flex'>5 miles</div> */}
            </div>
            <span className="isAvailable badge rounded-pill bg-light text-dark d-flex justify-content-between align-items-center">
              <div className={'isAvailableContainer me-2' + (Object.keys(character).length ? (character.is_available ? " isAvailableColor" : " isNotAvailableColor") : "")}></div>
              <span>{Object.keys(character).length ? (character.is_available ? "Ava" : "Not Ava") : ""}</span>
            </span>
          </div>
          {/* <div className='orderText my-3 d-flex justify-content-center align-items-center text-white'>
            Order for {showBookNow ? "Now" : "Later"}
          </div> */}
        </div>
      </div>
      <div className='paymentDesc mt-2 mb-3'>{Object.keys(allStrings).length ? allStrings.you_will_pay : ""} {Object.keys(character).length ? (character.name) : ""} {Object.keys(allStrings).length ? allStrings.in_cash_upon_her_arrival : ""}</div>
      {/* <div className='bookingPagePriceContainer position-relative d-flex flex-column justify-content-center align-items-center w-100 px-3 p-2 mb-3' onClick={() => setShowSelectHrs(!showSelectHrs)}>
        <div className='d-flex justify-content-between align-items-center w-100'>
          <div className='text-primary bookingPagePriceText'>{bookingHrs}H FOR {Object.keys(character).length ? (bookingHrs * character.hourly_price) : bookingHrs}</div>
          <div className='d-flex'><img src={downArrow} alt='' /></div>
        </div>
        {showSelectHrs && (
          <div className="bookingHrsOptions">
            <div className="bookingHrsOption px-3 p-2" onClick={() => setBookingHrs(1)}>
              1H FOR {Object.keys(character).length ? character.hourly_price : bookingHrs}
            </div>
            <div className="bookingHrsOption px-3 p-2" onClick={() => setBookingHrs(2)}>
              2H FOR {Object.keys(character).length ? (2 * character.hourly_price) : bookingHrs}
            </div>
          </div>
        )}
      </div> */}
      <div className='position-relative w-100 d-flex align-items-center justify-content-end mb-10'>
        <img className='position-absolute bookingPagePriceDDImg' src={downArrow} alt='' />
        <select className='bookingPagePriceContainer text-primary bookingPagePriceText border-0 d-flex justify-content-center align-items-center w-100 px-3 p-2' value={bookingHrs} onChange={(e) => {if(showBookNow){bookNowApiCall(e.target.value)} else {setDate(""); setStartTime(""); setEndTime(""); setSlotIds("")};setBookingHrs(e.target.value); setTodaysSlots([])}}>
          <option value={1} className='bookingHrsOption'>{Object.keys(allStrings).length ? allStrings['1h_for'] : ""} {Object.keys(character).length ? (1 * character.hourly_price) : bookingHrs}</option>
          <option value={2} className='bookingHrsOption'>{Object.keys(allStrings).length ? allStrings['2h_for'] : ""} {Object.keys(character).length ? (2 * character.hourly_price) : bookingHrs}</option>
        </select>
      </div>
      {/* <div className={'d-flex bookingPageInputs justify-content-start align-items-center w-100 px-3 p-2 mb-3' + (dateTime ? "" : " bookingDateTimePlaceholder")}>Date & Time*</div> */}
      {showBookNow
        ? <>
            <div className='d-flex bookingPageInputs justify-content-start align-items-center w-100 px-3 p-2 mb-3'>{date ? (dayjs(date).format("DD-MM-YYYY")) : ""}</div>
            { todaysSlots.length
              ? <>
                <div className='w-100 text-start d-flex mb-1'>{Object.keys(allStrings).length ? allStrings.available_slots : ""}</div>
                <div className='row w-100 mb-2'>
                  {todaysSlots.map((item, index) => {
                    return (
                      <div key={index} className='col-6 p-1 d-flex justify-content-center align-items-center'>
                        <button className={"btn w-100 h-100" + (slotIds ? (slotIds === item.id ? ' bg-primary text-white' : ' btn-outline-secondary') : "' btn-outline-secondary")} onClick={() => handleButtonClick(item)}>
                          {item.start_time + "-" + item.end_time}
                        </button>
                      </div>
                    )
                  })}
                </div>
              </>
              : <>
                <div className='fs-5 text-center mb-2 fw-bold text-primary'>
                {Object.keys(allStrings).length ? allStrings.there_is_no_slots_available_for_today : ""}
                </div>
                <button className='border-0 mb-3 text-white w-100 text-center bg-primary bookingPageBookLaterBtn' onClick={() => {
                  sessionStorage.setItem("showBookNow", JSON.stringify(false));
                  window.location.reload()
                }}>
                  {allStrings.book_girl_for_later ? allStrings.book_girl_for_later : "Book girl for Later"}
                </button>
              </>
            }
          </>
        : <>
            <DateTimePicker setTodaysSlots={setTodaysSlots} setDateValues={setDateValues} allStrings={allStrings} date={date} bookingHrs={bookingHrs} startTime={startTime} endTime={endTime} />
            <div className="text-danger font-small mb-3 w-100 text-start">{showBookingFormError ? (!(date) ? "Select Date" : <></>) : <></>}</div>
            { todaysSlots.length
              ? <>
                  <div className='w-100 text-start d-flex mb-1'>{Object.keys(allStrings).length ? allStrings.available_slots : ""}</div>
                  <div className='row w-100'>
                    {todaysSlots.map((item, index) => {
                      return (
                        <div key={index} className='col-6 p-1 d-flex justify-content-center align-items-center'>
                          <button className={"btn w-100 h-100" + (slotIds ? (slotIds === item.id ? ' bg-primary text-white' : ' btn-outline-secondary') : "' btn-outline-secondary")} onClick={() => handleButtonClick(item)}>
                            {item.start_time + "-" + item.end_time}
                          </button>
                        </div>
                      )
                    })}
                  </div>
                  {showBookingFormError 
                    ? <div className="text-danger font-small w-100 text-start"> {(!(slotIds) ? "Select Time" : <></>)}</div>
                    : <></>
                  }
                </>
              : <div className='w-100 text-danger'>
                  {Object.keys(allStrings).length ? allStrings.please_select_or_change_the_date_to_get_available_slots : "Please Select or Change the Date to get available slots!"}
                </div>
            }
          </>
      }
      {showOtherBookingDetails
        ? <>
          <div className='addressLabel d-flex justify-content-start w-100 mb-2'>{Object.keys(allStrings).length ? allStrings.provide_your_address : ""}</div>
          <input className='d-flex bookingPageInputs justify-content-start align-items-center w-100 px-3 p-2' placeholder='Hotel / Apartment Name*' value={hotelAddress} onChange={(e) => setHotelAddress(e.target.value)} />
          <div className="text-danger font-small mb-10 w-100 text-start">{showBookingFormError ? (!hotelAddress ? "Enter Address" : <></>) : <></>}</div>
          {/* <input className='d-flex bookingPageInputs justify-content-start align-items-center w-100 px-3 p-2 mb-3' placeholder='Room number*' value={roomNumber} onChange={(e) => setRoomNumber(e.target.value)} /> */}
          {/* <input className='d-flex bookingPageInputs justify-content-start align-items-center w-100 px-3 p-2 mb-3' placeholder='Ward*' /> */}
          <div className='position-relative w-100 d-flex align-items-center justify-content-end'>
            <img className='position-absolute bookingPagePriceDDImg' src={downArrow} alt='' />
            <select className={'wardSelection border-primary text-primary border-0 d-flex justify-content-center align-items-center w-100 px-3 p-2' + (selectedWard ? "" : ' wardPlaceholder')} value={selectedWard} onChange={(e) => setSelectedWard(e.target.value)}>
              <option value="" disabled className=''>{Object.keys(allStrings).length ? allStrings.ward : "Ward*"}</option>
              {wardOptions?.map((opt, index) => {
                return (
                  <option key={index} value={opt} className=''>{opt}</option>
                )
              })}
            </select>
          </div>
          <div className="text-danger font-small mb-3 w-100 text-start">{showBookingFormError ? (!selectedWard ? "Select Ward" : <></>) : <></>}</div>
          <div className='bookingPageDesc mb-3'>
            {Object.keys(allStrings).length ? allStrings.you_will_receive_a_short_phone_call_to_verify_after_your_booking : ""}
          </div>
          <button className='bookingSubmitBtn bg-primary border-0 text-white p-2 w-100' onClick={handleBookingDetailSubmit}>
            {Object.keys(character).length
              ? `BOOK ${String(character.name).toUpperCase()} FOR ${(bookingHrs * character.hourly_price)} JPY (${bookingHrs}H)`
              : "BOOK MIKA FOR 50,000 JPY (2H)"
            }
          </button>
          {/* { showBookingFormError
              ? <div className='w-100 d-flex justify-content-center text-center text-primary my-2 fw-bold'>Invalid detail, Please fill the form properly!</div>
              : <></>
            } */}
          <div className="modal fade" id="congratulationsModal" ref={congratsModalRef}>
            <div className="modal-dialog modal-dialog-centered d-flex justify-content-center align-items-center">
              <div className="modal-content border-0 bookingPageCongratsContainer">
                <div className="modal-body d-flex justify-content-center align-items-center flex-column p-3">
                  <div className='d-flex justify-content-center mb-2 align-items-center'>
                    <img className='' src={congratsImg} alt='' />
                  </div>
                  <div className='congratsHeading text-primary mb-2'>
                    {Object.keys(allStrings).length ? allStrings.congratulation : ""}
                  </div>
                  <div className='congratsTitle my-2 mb-4'>
                    {Object.keys(allStrings).length ? allStrings.your_booking_confirmed : ""}
                  </div>
                  <div className='congratsDesc mb-2'>
                    {Object.keys(allStrings).length ? allStrings.in_publishing_and_graphic_design__lorem_ipsum_is_a_placeholder_text_commonly_used_to_demonstrate_the : ""}
                  </div>
                  <div className='my-3'>
                    <img className='congratsLoaderImg' src={loaderImg} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="modal fade" id="" ref={enableLocationRef}>
            <div className="modal-dialog modal-dialog-centered d-flex justify-content-center align-items-center">
              <div className="modal-content enableLocationModalBox border-0">
                <div className="modal-body d-flex justify-content-between align-items-center flex-column p-4">
                  <img height={165} src={siteSettingsIcon} alt='' />
                  <div className='enableLocationModalBoxDesc text-center mb-3 mt-2'>
                  {Object.keys(allStrings).length 
                    ? allStrings.click_the_lock_icon_in_your_browser_s_address_bar___turn_on_location 
                    : "Click the lock icon in your browser's address bar & Turn on location."
                  } 
                  </div>
                  <div className='d-flex justify-content-center w-100'>
                    <button className='border-0 text-white bg-primary mx-2' onClick={() => enableLocationModal.hide()}>OK</button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </>
        : <></>
      }
    </div>
  )
}

export default BookingPage