import React from 'react'
import loaderImg from "../images/Mask group.svg"
import "./Loader.css"

const Loader = ({show}) => {
  return (
    <>
      { show
        ? <div className='position-fixed loaderContainer'>
            <img src={loaderImg} alt='' />
          </div>
        : <></>
      }
    </>
  )
}

export default Loader