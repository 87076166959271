import React, { useEffect } from 'react'
import "./LoginForm.css"
import logoImg from "../images/Group 427318752 (1).svg"
import mailImg from "../images/mail.png"
import { useNavigate } from 'react-router-dom'
import { routes } from '../Routes'
// import lockImg from "../images/Mask group (5).png"

const LoginForm = () => {
  const navigate = useNavigate()
  useEffect(() => {
    sessionStorage.setItem("routeFrom", routes.loginForm)
    navigate(routes.enterMobileNumber)
  }, [navigate])
  return (
    <>
      <div className='loginFormContainer d-flex justify-content-center align-items-center'>
        <div className='loginFormContent d-flex justify-content-center flex-column align-items-center p-3'>
          <img className='mt-4' src={logoImg} alt='' />
          <div className='loginFormBrandTitle mt-2 text-primary'>EscortsJP.com</div>
          <div className='loginFormWelcomeMsg mt-4'>Welcome Back!</div>
          <div className='loginFormWelcomeSubMsg'>Let's login for dream night</div>
          <div className='loginFormInputContainer border-primary w-100 mt-4 mb-2 p-2 d-flex justify-content-between align-items-center'>
            <img className='ms-1' src={mailImg} alt='' />
            <input className='border-0 bg-none ms-3 w-100' type='text' placeholder='Phone number' />
          </div>
          {/* <div className='loginFormInputContainer border-primary w-100 my-2 p-2 d-flex justify-content-between align-items-center'>
            <img className='ms-1' src={lockImg} alt='' />
            <input className='border-0 bg-none' type='text' placeholder='Password' />
          </div> */}
          <button className='loginFormGetOtpBtn border-0 bg-primary text-white w-100 my-2'>Get OTP</button>
        </div>
      </div>
    </>
  )
}

export default LoginForm