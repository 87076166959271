import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
// import { TimeClock } from '@mui/x-date-pickers/TimeClock';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
// import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import dayjs from 'dayjs';
import "./DateTimePicker.css"
import axios from 'axios';
import { baseURL, relativeURL } from "../APIS"
import Loader from './Loader';
import closeImg from "../images/Close Icon.svg"

const DateTimePicker = ({ date, setTodaysSlots, bookingHrs, setDateValues, startTime, endTime, allStrings }) => {
  const dateTimePickerModalRef = useRef();
  const [showTimePicker, setShowTimePicker] = useState(false)
  // const [showDateTimeModal, setShowDateTimeModal] = useState(false);
  const [dateVal, setDateVal] = useState(dayjs(date ? date : (new Date().setDate(new Date().getDate() + 1))))
  const [modal, setModal] = useState();
  const [showLoader, setShowLoader] = useState(false)
  // const [scheduleData, setScheduleData] = useState([])
  // const [tempStartTime, setTempStartTime] = useState("")
  // const [tempEndTime, setTempEndTime] = useState("")
  // const [timeSlotErrorMsg, setTimeSlotErrorMsg] = useState(false)

  // const handleButtonClick = (item) => {
  //   setSlotIds(item.id);
  //   setTempStartTime(item.start_time)
  //   setTempEndTime(item.end_time)
  // };

  useEffect(() => {
    // console.log(date)
    if (date) {
      setDateVal(dayjs(date));
    } else {
      const currentDate = new Date().setDate(new Date().getDate() + 1);
      setDateVal(dayjs(currentDate));
    }
  }, [date])

  const handleDateTimeChange = (date) => {
    setDateVal(date);
  }
  const handleDateSubmit = () => {
    setShowTimePicker(true);
    setShowLoader(true)
    let escortDetail = JSON.parse(sessionStorage.getItem("escortBooking"))
    let formatedDateTime = dateVal.format('YYYY-MM-DD');
    let data = {
      escort_id: escortDetail.id,
      selected_date: formatedDateTime,
      slot_duration: bookingHrs
    }
    modal.hide()
    axios.post(baseURL + relativeURL.escortsDateWiseAvl, data).then(res => {
      setShowLoader(false)
      if (res.data.success) {
        setDateValues(dateVal)
        // setScheduleData(res.data.data)
        setTodaysSlots(res.data.data)
      }
      console.log(res.data.data);
    }).catch((err) => {
      setShowLoader(false)
      console.log(err)
    })
  }
  // const handleTimeSubmit = () => {
  //   if(tempStartTime && tempEndTime){
  //     setDateTimeValues({ date: dateVal, startTime: tempStartTime, endTime: tempEndTime })
  //     modal.hide()
  //   } else {
  //     setTimeSlotErrorMsg(true)
  //     setTimeout(() => {
  //       setTimeSlotErrorMsg(false)
  //     }, 3000)
  //   }
  // }
  const openDateTimePicker = () => {
    if (dateTimePickerModalRef.current) {
      const tempModal = new window.bootstrap.Modal(dateTimePickerModalRef.current);
      setModal(tempModal);
      tempModal.show();
      setShowTimePicker(false);
      if (date) {
        setDateVal(dayjs(date));
      } else {
        const currentDate = new Date().setDate(new Date().getDate() + 1);
        setDateVal(dayjs(currentDate));
      }
    }
  }

  return (
    <>
      <Loader show={showLoader} />
      <div className={'d-flex bookingPageInputs bookingDateTimeContent justify-content-start align-items-center w-100 px-3 p-2' + (date ? "" : " bookingDateTimePlaceholder")} onClick={openDateTimePicker}>
        {(date ? (dayjs(date).format("DD-MM-YYYY")) : "Select Date*")}
      </div>
      <div className="modal fade" id="congratulationsModal" ref={dateTimePickerModalRef}>
        <div className="modal-dialog modal-dialog-centered d-flex justify-content-center align-items-center">
          <div className="modal-content position-relative border-0 dateTimePickerModal p-2">
            <div className='d-flex justify-content-end align-items-center w-100'>
              {/* <div className='text-primary ms-4 selectTimeText'> {showTimePicker ? (scheduleData.length ? "Available Time Slot" : "") : ""}</div> */}
              <button className={`border-0 bg-none d-flex justify-content-center align-items-center dateModalCloseIcon`} onClick={() => {modal.hide()}}>
                <img src={closeImg} alt='' />
              </button>
            </div>
            {showTimePicker
              ? <>
                {/* <div className={`modal-body h-100 d-flex justify-content-${scheduleData.length ? "between" : "center"} align-items-center flex-column p-0`} style={{minHeight: scheduleData.length ? "50vh" : "100px"}}>
                  {scheduleData.length
                    ? <>
                        <div className='row timePicker w-100 p-3'>
                          {scheduleData?.map((item, index) => {
                            return (
                              <div className='col-6 p-1 d-flex justify-content-center align-items-center' key={index}>
                                <button className={"btn w-100 h-100" + (slotIds ? (slotIds === item.id ? ' bg-primary text-white' : ' btn-outline-secondary') : "' btn-outline-secondary")} onClick={() => handleButtonClick(item)} >
                                  <span>{item.start_time + "-" + item.end_time}</span>
                                </button>
                              </div>
                            )
                          })}
                          {timeSlotErrorMsg
                            ? <div className='text-danger selectTimeText ps-0 w-100 align-left'>{Object.keys(allStrings).length ? allStrings.please_select_a_time_slot : ""}</div>
                            : <></>
                          }
                        </div>
                      </>
                    : <></>
                  }
                  {scheduleData.length
                    ? <div className='d-flex w-100 justify-content-end mb-2 me-4'>
                      <button className='px-3 bg-primary DateTimeSubmitBtn border-0 text-white' onClick={handleTimeSubmit}>{Object.keys(allStrings).length ? allStrings.submit : ""}</button>
                    </div>
                    : <></>
                  }
                  {!scheduleData.length 
                    ? <div className="w-100 d-flex justify-content-center align-items-center fw-bold text-primary DateTimeDataNotAvl">
                        {Object.keys(allStrings).length ? allStrings.data_is_not_available : ""}
                      </div> 
                    : <></>
                  }
                </div> */}
                </>
              : <div className="modal-body h-100 d-flex justify-content-center align-items-center flex-column p-0">
                <StyledEngineProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDatePicker orientation="portrait" minDate={dayjs(new Date().setDate(new Date().getDate() + 1))} views={['day']} value={dateVal} onChange={handleDateTimeChange} />
                  </LocalizationProvider>
                </StyledEngineProvider>
                <div className='d-flex w-100 justify-content-end mb-2 me-4'>
                  <button className='px-3 bg-primary DateTimeSubmitBtn border-0 text-white' onClick={handleDateSubmit}>
                    {Object.keys(allStrings).length ? allStrings.submit : ""}
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default DateTimePicker