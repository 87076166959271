import React, { useEffect, useState } from 'react'
import "./FavouritesPage.css"
import backImg from "../images/arrow-left (1).svg"
// import notificationImg from "../images/Mask group (1).svg"
import favouriteIcon from "../images/Group 427318819.svg"
import { routes } from '../Routes'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { baseURL, relativeURL } from '../APIS'
import Loader from './Loader'
import crossIcon from "../images/Group 427318837 (1).svg";
import dangerIcon from "../images/exclamation (1).svg"
import { useRef } from 'react'
import rightImg from "../images/next 1.svg"

const FavouritesPage = () => {
  const navigate = useNavigate();
  const confirmRemoveFavRef = useRef(null);

  const [showActiveEscorts, setShowActiveEscorts] = useState(false);
  const [allActiveFavData, setAllActiveFavData] = useState([])
  const [showLoader, setShowLoader] = useState(false);
  const [favData, setFavData] = useState([])
  const [removeFavConfirmBoxModal, setRemoveFavConfirmBoxModal] = useState()
  const [itemToRemove, setItemToRemove] = useState({})
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [allStrings, setAllString] = useState({})
  const sessionStoreAllString = sessionStorage.getItem("allStrings");
  const [showFavouriteProfile, setShowFavouriteProfile] = useState(false);
  const [favouriteProfile, setFavouriteProfile] = useState("");
  const [currentPhotoNumber, setCurrentPhotoNumber] = useState(0);
  const [photosLength, setPhotosLength] = useState("");
  let dragCardValues = {}

  useEffect(() => {
    setShowActiveEscorts(false)
    let userDetails = sessionStorage.getItem("userVerified")
    let data = { user_id : "", filter : "" }
    // let tempUserLoggedIn = false
    if(userDetails) {
      setUserLoggedIn(true)
      userDetails=JSON.parse(userDetails);
      data.user_id=userDetails.id;
      data.filter="is_favourite";
      // tempUserLoggedIn = true
      const fetchData = async () => {
        setShowLoader(true)
        await axios.post(baseURL + relativeURL.escortsList, data).then(res => {
          setShowLoader(false)
          if(res.data.success) {
            if(res.data.data.length) {
              let tempFavData = res.data.data;
              setFavData(tempFavData)
              setAllActiveFavData(tempFavData)
            }
          }
        }).catch(err => {
          console.error(err)
          setShowLoader(false)
        })
      }
      fetchData()
    } else {
      // tempUserLoggedIn = false
      setUserLoggedIn(false)
      if(sessionStorage.getItem("likedArr")) {
        let tempFavData = JSON.parse(sessionStorage.getItem("likedArr"));
        setFavData(tempFavData)
        setAllActiveFavData(tempFavData)
      }
    }
  }, [])

  useEffect(() => {
    let tempAllStrings = sessionStorage.getItem("allStrings")
    if(tempAllStrings) {
      tempAllStrings = JSON.parse(tempAllStrings);
      setAllString(tempAllStrings)
    }
  }, [sessionStoreAllString])

  const handleShowActiveEscorts = (type) => {
    if(type === "active") {
      let tempData = favData.filter((item) => {
        if(item.is_available){
          return true
        } else {
          return false
        }
      })
      setAllActiveFavData(tempData)
      setShowActiveEscorts(true);
    } else {
      let tempData = favData
      setAllActiveFavData(tempData)
      setShowActiveEscorts(false);
    }
  }

  const handleRemoveFavourite = (item) => {
    if (confirmRemoveFavRef.current) {
      const modal = new window.bootstrap.Modal(confirmRemoveFavRef.current);
      setRemoveFavConfirmBoxModal(modal);
      setItemToRemove(item);
      modal.show();
    }
  }

  const handleConfirmBox = () => {
    let item = itemToRemove
    if(userLoggedIn) {
      let userDetails = sessionStorage.getItem("userVerified")
      userDetails = JSON.parse(userDetails)
      let data = {escort_ids: item.id, user_id: userDetails.id, is_favourite: 0}
      setShowLoader(true)
      axios.post(baseURL + relativeURL.favouriteEscort, data).then(res => {
        setShowLoader(false)
        let tempFavData = favData.filter((obj) => {
          if(obj.id === item.id) {
            return false
          } else {
            return true
          }
        })
        if(showActiveEscorts === true) {
          let tempData = tempFavData.filter((item) => {
            if(item.is_available){
              return true
            } else {
              return false
            }
          })
          setAllActiveFavData(tempData)
        } else {
          setAllActiveFavData(tempFavData)
        }
        setFavData(tempFavData)
        removeFavConfirmBoxModal.hide()
      }).catch(err => {
        setShowLoader(false)
        console.log(err)
      })
    } else {
      // let item = itemToRemove
      console.log(item)
      let tempFavData = favData.filter((obj) => {
        if(obj.id === item.id) {
          return false
        } else {
          return true
        }
      })
      sessionStorage.setItem("likedArr", JSON.stringify(tempFavData))
      if(showActiveEscorts === true) {
        let tempData = tempFavData.filter((item) => {
          if(item.is_available){
            return true
          } else {
            return false
          }
        })
        setAllActiveFavData(tempData)
      } else {
        setAllActiveFavData(tempFavData)
      }
      setFavData(tempFavData)
      removeFavConfirmBoxModal.hide()
    }
  }

  const onFavouriteBookingPageClick = (index) => {
    setShowFavouriteProfile(true)
    setFavouriteProfile(allActiveFavData[index])
    setPhotosLength(allActiveFavData[index].escort_images.length)
    setCurrentPhotoNumber(0)
  }

  const handleBookingFavouriteProfile = () => {
    sessionStorage.setItem("escortBooking", JSON.stringify(favouriteProfile))
    if(favouriteProfile.is_available) {
      sessionStorage.setItem("showBookNow", JSON.stringify(true))
    } else {
      sessionStorage.setItem("showBookNow", JSON.stringify(false))
    }
    sessionStorage.setItem("forBookingPage", routes.favorites)
    if(userLoggedIn) {
      navigate(routes.bookingPage);
    } else {
      sessionStorage.setItem("routeFrom", routes.favorites)
      navigate(routes.enterMobileNumber);
    }
  }

  const onImageChange = (coords) => {
    let btn = document.getElementById("favProfileImageChangeBtn")
    let rect = btn.getBoundingClientRect()
    let btnWidth = rect.width
    let clickedX = coords.clientX - rect.x
    if (clickedX <= btnWidth / 2) {
      onLeftClick()
    } else {
      onRightClick()
    }
  }
  const onLeftClick = () => {
    if (currentPhotoNumber > 0) {
      setCurrentPhotoNumber(currentPhotoNumber - 1)
    } else if (currentPhotoNumber === 0) {
      setCurrentPhotoNumber(photosLength - 1)
    }
  }
  const onRightClick = () => {
    if (currentPhotoNumber < (photosLength - 1)) {
      setCurrentPhotoNumber(currentPhotoNumber + 1)
    } else if (currentPhotoNumber === (photosLength - 1)) {
      setCurrentPhotoNumber(0)
    }
  }
  const onCardDragStart = (e) => {
    dragCardValues = { clientX: e.clientX, clientY: e.clientY }
  }
  const onCardDragEnd = (e) => {
    if (dragCardValues.clientX === e.clientX && dragCardValues.clientY === e.clientY) {
      let coords = { clientX: e.clientX, clientY: e.clientY };
      onImageChange(coords);
    }
  }

  const handleBackBtn = () => {
    if(showFavouriteProfile) {
      setShowFavouriteProfile(false)
    } else {
      navigate(routes.card)
    }
  }

  return (
    <>
      <Loader show={showLoader} />
      <div className='favouritesPageContainer'>
        <div className='favouritesPage-header bg-primary w-100 d-flex flex-column align-items-center justify-content-start pb-2 mb-1'>
          <div className='d-flex w-100 justify-content-between'>
            <button className='bg-primary border-0 m-2 d-flex align-items-center justify-content-center' onClick={() => handleBackBtn()}>
              <img className='d-flex align-items-center justify-content-center' src={backImg} alt='' />
            </button>
            {/* <button className='bg-primary border-0 m-2 d-flex align-items-center justify-content-center'>
              <img className='d-flex align-items-center justify-content-center' src={notificationImg} alt='' />
            </button> */}
          </div>
          <div className='d-flex align-items-center justify-content-center '>
            <img className='d-flex align-items-center justify-content-center' src={favouriteIcon} alt='' />
          </div>
          <div className='favouriteTitle d-flex align-items-center justify-content-center text-white my-2'>
            { Object.keys(allStrings).length ? allStrings.favorites : ""}
          </div>
          {/* <div className='favouriteDesc text-white d-flex align-items-center justify-content-center mb-4'>
          { Object.keys(allStrings).length ? allStrings.lorem_ipsum_is_a_placeholder_text_commonly_used_to : ""}
          </div> */}
        </div>
        {showFavouriteProfile
          ? <div className='favouriteProfileContainer w-100 d-flex mt-4 justify-content-center'>
              <div className='cardContainer w-100 position-relative'>
                <div style={{ backgroundImage: `url("${favouriteProfile.escort_images[currentPhotoNumber]?.file_path}")`, height: 'calc(90vh - 165px) !important'}} className={'card position-absolute w-100 m-0 border-0 justify-content-between'}>
                  <div className='mt-2 mx-4 d-flex justify-content-center FavProfileImageCounterContainer'>
                    {Array(favouriteProfile.escort_images.length).fill()?.map((item, idx) => {
                      return (
                        <div className={`h-100 mx-1 imageCounter ${(currentPhotoNumber === idx ? "bg-activeImg" : "bg-inactiveImg")}`} style={{ width: "calc(100% / " + favouriteProfile.escort_images.length + ")" }} key={idx}></div>
                      )
                    })}
                  </div>
                  <button id="favProfileImageChangeBtn" className='w-100 h-100 border-0 imageChangerBtn d-flex justify-content-between align-items-center px-4' onMouseDown={onCardDragStart} onMouseUp={onCardDragEnd}>
                    <div className='tapImageChangeContainer bg-white d-flex justify-content-center align-items-center p-2'>
                      <img className='leftTapImageChange tapImageChange' src={rightImg} alt='' />
                    </div>
                    <div className='tapImageChangeContainer bg-white d-flex justify-content-center align-items-center p-2'>
                      <img className='tapImageChange' src={rightImg} alt='' />
                    </div>
                  </button>
                  <div className={'cardInfoContainer p-2 justify-content-end align-items-center flex-column d-flex'}>
                    <div className='w-100 justify-content-between align-items-center d-flex px-2 mb-3'>
                      <div>
                        <div className='heading_h3 text-white'>{favouriteProfile.name}, {favouriteProfile.age}</div>
                        <div className='escortNearBy d-flex'>¥ {favouriteProfile.hourly_price}</div>
                      </div>
                      <span className="isAvailable badge rounded-pill bg-light text-dark d-flex justify-content-between align-items-center">
                        <div className={'isAvailableContainer me-1' + (favouriteProfile.is_available ? " isAvailableColor" : " isNotAvailableColor")}></div>
                        <span>{(favouriteProfile.is_available ? "Ava" : "Not Ava")}</span>
                      </span>
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                      <button className={`bookNowBtn py-2 px-3 text-primary bg-white mb-3`} onClick={() => handleBookingFavouriteProfile()}>
                        {favouriteProfile.is_available
                          ? allStrings.book_now ? allStrings.book_now : "Book Now"
                          : allStrings.book_girl_for_later ? allStrings.book_girl_for_later : "Book girl for Later"
                        }
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          : <>
              <div className='d-flex align-items-center justify-content-center'>
                <button className={'showActiveEscortsBtn p-2 mx-2 my-3 d-flex align-iems-center justify-content-center border-0'+(showActiveEscorts ? " text-primary bg-secondary" : " text-white bg-primary")} onClick={() => handleShowActiveEscorts("all")}>{allStrings.all ? allStrings.all : "All"}</button>
                <button className={'showActiveEscortsBtn p-2 mx-2 my-3 d-flex align-iems-center justify-content-center border-0'+(showActiveEscorts ? " text-white bg-primary" : " text-primary bg-secondary")} onClick={() => handleShowActiveEscorts("active")}>{allStrings.active ? allStrings.active : "Active"}</button>
              </div>
              <div className='row w-100 px-2 mt-1 favouriteItemsContainer'>
                { allActiveFavData.length
                  ? <>
                      { allActiveFavData?.map((obj, index) => {
                        return(
                          <div className='col-6 mb-3 px-2' key={index}>
                            <div className='card m-0 border-0 justify-content-between' style={{ backgroundImage: 'url(' + obj.escort_images[0].file_path + ')' }} onClick={() => onFavouriteBookingPageClick(index)}>
                              <div className='mt-2 mx-2 d-flex justify-content-between align-items-center favouriteAvail'>
                                <div className={"favouritesCardsAvl" + (obj.is_available ? " favAvailable" : " favNotAvailable")}></div>
                                <button className="p-0 d-flex align-items-center justify-content-center border-0 bg-none" onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveFavourite(obj)
                                }}>
                                  <img src={crossIcon} alt='' />
                                </button>
                              </div>
                              <div className='cardInfoContainer p-2 d-flex flex-column justify-content-end'>
                                <div className='w-100 justify-content-between align-items-center d-flex px-1'>
                                  <div>
                                    <div className='favouriteNameAge text-white'>
                                      {obj.name + ", " + obj.age}
                                    </div>
                                    {/* <div className='favouriteEscortNearBy d-flex'>5 miles</div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  : <>
                      {/* { userLoggedIn  */}
                        {/* ?  */}
                          <div className='d-flex justify-content-center align-items-center fw-bold'> 
                            { showActiveEscorts
                              ? allStrings.no_active_items ? allStrings.no_active_items : "No Active Profiles!"
                              : allStrings.no_favourite_items ? allStrings.no_favourite_items : "No Favourite Profiles!"
                            } 
                          </div>
                        {/* : <div>
                            <div className='text-center mt-5 text-primary fw-bold'>{allStrings.login_to_view_favourites ? allStrings.login_to_view_favourites : "Login to view Favourites"}</div>
                            <button className='bg-primary border-0 text-white fw-bold favLoginBtn w-100 d-flex justify-content-center align-items-center mt-3' onClick={() => {sessionStorage.setItem("routeFrom", routes.favorites); navigate(routes.enterMobileNumber)}}>{allStrings.login ? allStrings.login : "Login"}</button>
                          </div>
                      } */}
                    </>
                }
                <div className="modal fade" id="" ref={confirmRemoveFavRef}>
                  <div className="modal-dialog modal-dialog-centered d-flex justify-content-center align-items-center">
                    <div className="modal-content removeFavConfirmBox border-0">
                      <div className="modal-body d-flex justify-content-between align-items-center flex-column p-4">
                        <img fill={"#FF2121"} height={75} width={75} src={dangerIcon} alt='' />
                        {/* <div className='d-flex justify-content-start align-items-center flex-column'> */}
                          <div className='removeFavConfirmBoxText text-center my-2'>{allStrings.are_you_sure ? allStrings.are_you_sure : "Are you sure?"}</div>
                          <div className='removeFavConfirmBoxDesc text-center mb-3'>{allStrings.remove_this_escort_profile ? allStrings.remove_this_escort_profile : "Remove this Escort profile."}</div>
                        {/* </div> */}
                        <div className='d-flex justify-content-center w-100'>
                          <button className='border-0 text-white bg-primary mx-2' onClick={handleConfirmBox}>{allStrings.yes ? allStrings.yes : "Yes"}</button>
                          <button className='bg-none border-0 text-primary border-primary mx-1' onClick={() => removeFavConfirmBoxModal.hide()}>
                            {allStrings.no ? allStrings.no : "No"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
        }
      </div>
    </>
  )
}

export default FavouritesPage