import React, { useEffect, useState } from 'react'
import "./Help.css"
import headerImg from "../images/Group 427318752 (1).png"
import swipeRight from "../images/Mask group (1).png"
import swipeLeft from "../images/Mask group (3).png"
import heart from "../images/Mask group (2).png"
import { useNavigate } from 'react-router-dom'
import { routes } from "../Routes"
import Loader from './Loader'
import axios from 'axios'
import { baseURL, relativeURL } from '../APIS'

const Help = () => {
  const navigate = useNavigate();
  const [allStrings, setAllString] = useState({})
  // const sessionStoreAllString = sessionStorage.getItem("allStrings");
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    checkAllStrings()
    const helpContentStartAroundJustifyContent = () => {
      const flexContainer = document.getElementById('helpContent');
    
      // Check if content overflows on the Y-axis
      const isOverflowed = flexContainer.scrollHeight > flexContainer.clientHeight;
    
      // Apply justify-content based on overflow condition
      flexContainer.style.justifyContent = isOverflowed ? 'start' : 'space-around';
    }
    helpContentStartAroundJustifyContent()
    window.addEventListener("DOMContentLoaded", helpContentStartAroundJustifyContent())
    window.addEventListener("resize", helpContentStartAroundJustifyContent())
  }, [])

  const checkAllStrings = () => {
    let allStrings = sessionStorage.getItem("allStrings")
    if (!allStrings) {
      setShowLoader(true)
      axios.post(baseURL + relativeURL.getAllStrings).then(res => {
        setShowLoader(false)
        if (res.data.success) {
          setAllString(res.data.data)
        }
      }).catch(err => {
        setShowLoader(false)
        console.log(err)
      })
    } else {
      setAllString(JSON.parse(sessionStorage.getItem("allStrings")))
    }
  }

  return (
    <div className='helpContainer'>
      <Loader show={showLoader} />
      <div id='helpContent' className='helpContent d-flex align-items-center flex-column'>
        <div className='helpHeading my-2 d-flex justify-content-center align-items-center'>
          <img src={headerImg} alt='' />
          <div className='headertitle ms-2'> EscortsJP.com </div>
        </div>
        <div className='d-flex my-3 justify-content-center align-items-center'>
          <div className='greetingTitle w-75'>{Object.keys(allStrings).length ? allStrings.welcome_to_escortsjp_com : ""}</div>
        </div>
        <div className='d-flex justify-content-center flex-column align-items-center w-100'>
          <div className='itemContainer mt-1 mb-2 px-3 py-3 w-100 d-flex justify-content-around align-items-center bg-light-secondary'>
            <img className='me-2' src={swipeRight} alt='' />
            <div className='itemText'>{Object.keys(allStrings).length ? allStrings.swipe_right_to_add_a_profile_to_your_favorites : ""}</div>
          </div>
          <div className='itemContainer my-2 px-2 py-3 w-100 d-flex justify-content-around align-items-center bg-light-secondary'>
            <img className='me-2' src={swipeLeft} alt='' />
            <div className='itemText'>{Object.keys(allStrings).length ? allStrings.swipe_left_to_next_profile : ""}</div>
          </div>
          <div className='itemContainer mt-2 mb-1 px-2 py-3 w-100 d-flex justify-content-around align-items-center bg-light-secondary'>
            <img className='me-2' src={heart} alt='' />
            <div className='itemText'>{Object.keys(allStrings).length ? allStrings.lorem_ipsum_is_a_text_commonly_used_to : ""}</div>
          </div>
        </div>
        <div className='description my-3'>{Object.keys(allStrings).length ? allStrings.tap : ""} 
          <span className='bookNow text-primary'> “{Object.keys(allStrings).length ? allStrings.book_now : ""}” </span> 
          {Object.keys(allStrings).length ? allStrings.to_order_an_immediate_service_at_your_location : ""}
        </div>
        <div className='mt-1 mb-2'>
          <button className='border-0 bg-primary text-white helpBookNowBtn mb-1' onClick={() => navigate(routes.card)}>
            {Object.keys(allStrings).length ? allStrings.start_now : ""}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Help