import React, { useState, useEffect, useRef } from 'react'
// import TinderCard from 'react-tinder-card'
import axios from 'axios'
import { baseURL, relativeURL } from '../APIS'
// import closeImg from "../images/Group 427318773.svg"
// import likeImg from "../images/Group 427318774.svg"
import { useNavigate } from 'react-router-dom'
import { routes } from '../Routes'
import Loader from './Loader'
import headerImg from "../images/Group 427318752.png"
import profileImg from "../images/Mask group.png"
import "./Card.css";
import dangerIcon from "../images/exclamation (1).svg"
import SwipeCard from './SwipeCard'
import Dashboard from './Dashboard'

const CardContainer = () => {
  const navigate = useNavigate();
  const [characters, setCharacters] = useState([])
  const [photosLength, setPhotosLength] = useState(0)
  const [currentPhotoNumber, setCurrentPhotoNumber] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const [likedArr, setLikedArr] = useState([])
  const [unLikedArr, setUnLikedArr] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isEscort, setIsEscort] = useState(false)
  const [allStrings, setAllString] = useState({})
  // const sessionStoreAllString = sessionStorage.getItem("allStrings");
  const removeLikesConfirmBoxRef = useRef()
  const [removeLikesModal, setRemoveLikesModal] = useState()
  const [originalLengthOfData, setOriginalLengthOfData] = useState(0)
  const [countUnLikeCycle, setCountUnLikeCycle] = useState(0)
  const [autoNavigateToFavourite, setAutoNavigateToFavourite] = useState(false)
  const [isFavourites, setIsFavourites] = useState(false)
  const [autoNavigateFavCount, setAutoNavigateFavCount] = useState(0)
  const autoNavigateFavRef = useRef(null)
  // let touchCardValues = {}
  // let dragCardValues = {}
  // let touchCloseBtnValues = {}
  // let dragCloseBtnValues = {}
  // let touchBookNowBtnValues = {}
  // let dragBookNowBtnValues = {}
  // let touchLikeBtnValues = {}
  // let dragLikeBtnValues = {}
  let lastDirection = ""
  // const currentIndexRef = useRef(activeIndex)
  // const childRefs = useMemo(
  //   () => Array(characters.length)
  //     .fill(0)
  //     .map((i) => React.createRef()),
  //   [characters.length]
  // )
  // const canSwipe = activeIndex >= 0

  useEffect(() => {
    const checkAllStrings = () => {
      let allStrings = sessionStorage.getItem("allStrings")
      if (!allStrings) {
        setShowLoader(true)
        axios.post(baseURL + relativeURL.getAllStrings).then(res => {
          setShowLoader(false)
          if (res.data.success) {
            setAllString(res.data.data)
          }
        }).catch(err => {
          setShowLoader(false)
          console.log(err)
        })
      } else {
        setAllString(JSON.parse(sessionStorage.getItem("allStrings")))
      }
    }
    checkAllStrings()
  }, [])

  useEffect(() => {
    document.body.style.overflow = "hidden"
    return(() => {
      document.body.style.overflow = "auto"
    })
  }, [])

  // const swipe = async (dir) => {
  //   if (canSwipe && activeIndex < characters.length) {
  //     await childRefs[activeIndex].current.swipe(dir) // Swipe the card!
  //   }
  // }
  const swiped = (direction, character) => {
    lastDirection = direction;
    if (direction === "left") {
      // setUnLikedArr([...unLikedArr, character])
    } else if (direction === "right") {
      setLikedArr([...likedArr, character])
      if (userLoggedIn) {
        let userDetails = sessionStorage.getItem("userVerified")
        userDetails = JSON.parse(userDetails)
        let data = { escort_ids: String(character.id), user_id: userDetails.id, is_favourite: "1" }
        setShowLoader(true)
        axios.post(baseURL + relativeURL.favouriteEscort, data).then(res => {
          setShowLoader(false)
          // console.log(res.data)
        }).catch(err => {
          setShowLoader(false)
          console.log(err)
        })
      } else {
        sessionStorage.setItem("likedArr", JSON.stringify([...likedArr, character]))
      }
    }
  }
  const outOfFrame = (character, index) => {
    let updatedCharacters = characters.filter((chr) => {
      if (chr.id === character.id) {
        return false
      } else {
        return true
      }
    })
    let tempUnLikedArr = unLikedArr
    if(lastDirection==="left") {
      tempUnLikedArr = [...unLikedArr, character]
      setUnLikedArr([...unLikedArr, character])
    }
    if(index===0){
      if(tempUnLikedArr.length) {
        // currentIndexRef.current >= index && childRefs[index].current.restoreCard()
        if((countUnLikeCycle<2) && (tempUnLikedArr.length === originalLengthOfData) && (tempUnLikedArr.length>1)) {
          setCountUnLikeCycle(countUnLikeCycle+1)
          setCharacters(tempUnLikedArr.reverse())
          setUnLikedArr([])
          setPhotosLength(tempUnLikedArr[tempUnLikedArr.length - 1].escort_images.length)
          setActiveIndex(tempUnLikedArr.length - 1)
        } else {
          setCharacters([])
          setUnLikedArr([])
          setPhotosLength(0)
          setActiveIndex(0)
          // console.log(isFavourites)
          // if(!userLoggedIn) {
            if(tempUnLikedArr.length !== originalLengthOfData || likedArr.length || isFavourites) {
              setAutoNavigateToFavourite(true)
              setAutoNavigateFavCount(3)
              autoNavigateFavRef.current = setInterval(() => {
                setAutoNavigateFavCount((prevSeconds) => {
                  if (prevSeconds > 1) {
                    return prevSeconds - 1;
                  } else {
                    clearInterval(autoNavigateFavRef.current);
                    navigate(routes.favorites)
                    return 0;
                  }
                })
              }, 1000)
            }
          // }
        }
        // currentIndexRef.current = tempUnLikedArr.length - 1
      } else {
        // currentIndexRef.current >= index && childRefs[index].current.restoreCard()
        setCharacters(updatedCharacters)
        setPhotosLength(updatedCharacters[activeIndex - 1]?.escort_images.length)
        setActiveIndex(activeIndex - 1)
        // currentIndexRef.current = activeIndex - 1
      }
      setCurrentPhotoNumber(0)
    } else {
      // currentIndexRef.current >= index && childRefs[index].current.restoreCard()
      setCharacters(updatedCharacters)
      setPhotosLength(updatedCharacters[activeIndex - 1].escort_images.length)
      setCurrentPhotoNumber(0)
      setActiveIndex(activeIndex - 1)
      // currentIndexRef.current = activeIndex - 1
    }
  }
  // const onCardTouchStart = (e) => {
  //   touchCardValues = { clientX: e.touches[0].clientX, clientY: e.touches[0].clientY }
  // }
  // const onCardTouchEnd = (e) => {
  //   if (touchCardValues.clientX === e.changedTouches[0].clientX && touchCardValues.clientY === e.changedTouches[0].clientY) {
  //     let coords = { clientX: e.changedTouches[0].clientX, clientY: e.changedTouches[0].clientY };
  //     onImageChange(coords);
  //   }
  // }
  // const onCardDragStart = (e) => {
  //   dragCardValues = { clientX: e.clientX, clientY: e.clientY }
  // }
  // const onCardDragEnd = (e) => {
  //   if (dragCardValues.clientX === e.clientX && dragCardValues.clientY === e.clientY) {
  //     let coords = { clientX: e.clientX, clientY: e.clientY };
  //     onImageChange(coords);
  //   }
  // }
  const onImageChange = (coords) => {
    let btn = document.getElementById("imageChangeBtn_" + activeIndex)
    let rect = btn.getBoundingClientRect()
    let btnWidth = rect.width
    let clickedX = coords.clientX - rect.x
    if (clickedX <= btnWidth / 2) {
      onLeftClick()
    } else {
      onRightClick()
    }
  }
  const onLeftClick = () => {
    if (currentPhotoNumber > 0) {
      setCurrentPhotoNumber(currentPhotoNumber - 1)
    } else if (currentPhotoNumber === 0) {
      setCurrentPhotoNumber(photosLength - 1)
    }
  }
  const onRightClick = () => {
    if (currentPhotoNumber < (photosLength - 1)) {
      setCurrentPhotoNumber(currentPhotoNumber + 1)
    } else if (currentPhotoNumber === (photosLength - 1)) {
      setCurrentPhotoNumber(0)
    }
  }

  // const onCloseBtnClick = async (direction, character) => {
  //   await swipe(direction)
  // }
  const onLikeUnLikeBtnClick = (direction, chr, index) => {
    swiped(direction, chr)
    outOfFrame(chr, index)
  }
  const onBookNowBtnClick = (character) => {
    if(character.is_available) {
      sessionStorage.setItem("escortBooking", JSON.stringify(characters[activeIndex]))
      sessionStorage.setItem("showBookNow", JSON.stringify(true))
      sessionStorage.setItem("forBookingPage", routes.card)
      if (userLoggedIn) {
        navigate(routes.bookingPage)
      } else {
        sessionStorage.setItem("routeFrom", routes.card)
        navigate(routes.login)
      }
    }
  }
  // const onLikeBtnClick = async (direction, character) => {
  //   await swipe(direction)
  // }

  useEffect(() => {
    setShowLoader(true)
    let userDetails = sessionStorage.getItem("userVerified")
    let data = { user_id: "", filter: "" }
    let tempUserLoggedIn = false
    if (userDetails) {
      userDetails = JSON.parse(userDetails);
      data.user_id = userDetails.id;
      tempUserLoggedIn = true
      setUserLoggedIn(true)
      if(userDetails.user_role==="client"){
      } else {
        setIsEscort(true)
      }
    } else {
      tempUserLoggedIn = false
      setUserLoggedIn(false)
    }
    axios.post(baseURL + relativeURL.escortsList, data).then(res => {
      // setShowLoader(false)
      if (res.data.success) {
        let tempData = []
        tempData = res.data.data
        if (tempUserLoggedIn) {
          tempData = tempData.filter((item) => {
            if (item.is_favourite) {
              return false
            } else {
              return true
            }
          })
          if(tempData.length !== res.data.data.length) {
            setIsFavourites(true)
          } else {
            setIsFavourites(false)
          }
          setLikedArr([])
          sessionStorage.setItem("likedArr", JSON.stringify([]))
        } else {
          if(sessionStorage.getItem("likedArr")){
            let tempLikedArr = JSON.parse(sessionStorage.getItem("likedArr"));
            let tempData2 = []
            let tempLikedArr2 = []
            for(let i of tempData){
              let flag = true
              for(let j of tempLikedArr){
                if(i.id === j.id) {
                  flag = false
                  break
                }
              }
              if(flag) {
                tempData2 = [...tempData2, i]
              } else {
                tempLikedArr2 = [...tempLikedArr2, i]
              }
            }
            tempData = tempData2
            setLikedArr(tempLikedArr2)
          }
        }
        // console.log(tempData)
        if(tempData.length) {
          setPhotosLength(tempData[tempData.length - 1].escort_images.length)
        }
        setActiveIndex(tempData.length - 1)
        // currentIndexRef.current = tempData.length - 1
        setUnLikedArr([])
        setCountUnLikeCycle(0)
        setOriginalLengthOfData(tempData.length)
        setCharacters(tempData)
        setCurrentPhotoNumber(0)
        setTimeout(() => {
          setShowLoader(false)
        }, 1000)
      }
    }).catch(err => {
      setShowLoader(false)
      console.log(err)
    });
  }, [])

  const handleDropdown = () => {
    // console.log("test")
    let card = document.querySelector(".cardDropdown"); //declearing profile card element
    // let displayPicture = document.querySelector(".display-pictureDropdown"); //declearing profile picture

    // displayPicture.addEventListener("click", function () { //on click on profile picture toggle hidden class from css
    card.classList.toggle("hidden")
    // });
  }

  const handleBookingLater = () => {
    sessionStorage.setItem("escortBooking", JSON.stringify(characters[activeIndex]))
    sessionStorage.setItem("showBookNow", JSON.stringify(false))
    sessionStorage.setItem("forBookingPage", routes.card)
    if (userLoggedIn) {
      navigate(routes.bookingPage)
    } else {
      sessionStorage.setItem("routeFrom", routes.card)
      navigate(routes.login)
    }
  }

  // useEffect(() => {
  //   sessionStorage.setItem("likedArr", JSON.stringify(likedArr))
  //   sessionStorage.setItem("unLikedArr", JSON.stringify(unLikedArr))
  // }, [likedArr, unLikedArr])

  const handleResetLikesConfirmBox = () => {
    if (removeLikesConfirmBoxRef.current) {
      const modal = new window.bootstrap.Modal(removeLikesConfirmBoxRef.current);
      setAutoNavigateToFavourite(false)
      clearInterval(autoNavigateFavRef.current)
      setRemoveLikesModal(modal);
      modal.show();
    }
  }

  const handleResetLikes = () => {
    if(userLoggedIn) {
      removeLikesModal.hide()
    } else {
      sessionStorage.removeItem("likedArr")
      removeLikesModal.hide()
      window.location.reload()
    }
  }

  const handleSwipe = (chr, direction, index) => {
    if (direction === 'right') {
      // Handle right swipe
      swiped(direction, chr)
      outOfFrame(chr, index)

    } else if(direction === 'left') {
      // Handle left swipe
      swiped(direction, chr)
      outOfFrame(chr, index)
    }
  };

  return (
    <>
      <Loader show={showLoader} />
      {!showLoader
        ? <>
            <div className='cardHeader w-100 mb-3 w-100'>
              <div className='d-flex justify-content-between align-items-center mb-3'>
                <div className='d-flex justify-content-start align-items-center' onClick={() => navigate(routes.home)}>
                  <div><img className='headerImg' src={headerImg} alt='' /></div>
                  <div className='cardHeaderTitle headertext'>EscortsJP.com</div>
                </div>
                <div className="profile_dropdown">
                  <ul>
                    <a href="*" onClick={(e) => {e.preventDefault()}} className="display-pictureDropdown">
                      <img src={profileImg} alt='' onClick={() => {
                        if(userLoggedIn) {
                          handleDropdown()
                        } else {
                          sessionStorage.setItem("routeFrom", routes.loginForm);
                          sessionStorage.setItem("showModalLoginBtn", JSON.stringify(true));
                          navigate(routes.enterMobileNumber);
                        }
                      }} />
                    </a>
                  </ul>
                  <div className="cardDropdown position-relative">
                    <ul>
                      <li>
                        { userLoggedIn 
                          ? <>
                              <a href="*" onClick={(e) => {
                                e.preventDefault();
                                navigate(routes.myAccount);
                              }}> {allStrings.my_account ? allStrings.my_account : "My Account"} </a>
                              <a href="*" onClick={(e) => {
                                e.preventDefault();
                                navigate(routes.myBookings);
                              }}> {allStrings.my_bookings ? allStrings.my_bookings : "My Bookings"} </a>
                              { isEscort 
                                ? <a href="*" onClick={(e) => {
                                    e.preventDefault();
                                    navigate(routes.manageAvailablity);
                                  }}> 
                                    {allStrings.manage_availability ? allStrings.manage_availability : "Manage Availablity"} 
                                  </a>
                                : <></>
                              }
                              <a href="*" onClick={(e) => {
                                e.preventDefault();
                                sessionStorage.removeItem("userVerified");
                                navigate(routes.home)
                                // window.location.reload()
                              }}> {allStrings.log_out ? allStrings.log_out : "Log Out"} </a>
                            </>
                          : <></>
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              { isEscort
                ? <></>
                : <div className='row w-100 d-flex justify-content-between my-2 px-0 ms-0'>
                    <div className='col ps-0 pe-2 mb-1'>
                      <button className={`bg-secondary fs-btn w-100 text-primary border-0 py-3 px-3 d-flex align-items-center justify-content-center headerBtn ${!characters.length ? "opacity6" : ""}`} onClick={() => characters.length ? handleBookingLater() : <></>}>
                        {Object.keys(allStrings).length ? allStrings.booking_later : "Booking Later"}
                      </button>
                    </div>
                    <div className='col ps-2 pe-0 mb-1'>
                      <button className='bg-primary fs-btn w-100 text-white border-0 py-3 px-3 d-flex align-items-center justify-content-center headerBtn' onClick={() => navigate(routes.favorites)}>{Object.keys(allStrings).length ? allStrings.favorites : ""}</button>
                    </div>
                  </div>
              }
            </div>
            { isEscort
              ? <>
                  <Dashboard />
                </>
              : <div className='cardContainer w-100 position-relative mb-4'>
                  {characters?.map((character, i) =>
                    <React.Fragment key={i}>
                      {i > (characters.length - 4)
                        ? <SwipeCard allStrings={allStrings} character={character} characters={characters} i={i} onImageChange={onImageChange} onBookNowBtnClick={onBookNowBtnClick} onLikeUnLikeBtnClick={onLikeUnLikeBtnClick} activeIndex={activeIndex} currentPhotoNumber={currentPhotoNumber} onSwipe={handleSwipe} />
                        : <></>
                      }
                    </React.Fragment>
                  )}
                  {!characters.length
                    ? <div className='d-flex flex-column align-items-center justify-content-center w-100'>
                        <div className='description my-3'> 
                          {Object.keys(allStrings).length ? allStrings.that_s_all_the_girls_we_have_right_now : "That's all the girls we have right now."} 
                          <br />
                          <span className='bookNow text-primary' onClick={() => navigate(routes.favorites)}> 
                            {Object.keys(allStrings).length ? ` ${allStrings.review_your_favorites} ` : " Review your favorites "} 
                          </span> 
                          {Object.keys(allStrings).length ? allStrings.and_choose_one_to_book_now : "and choose one to Book Now."}
                        </div>
                        {userLoggedIn
                          ? <></>
                          : <div className='my-3 mb-2'>
                              <button className='border-0 bg-primary text-white bookNowBtn mb-1 py-2 px-3' onClick={() => handleResetLikesConfirmBox()}>
                              {Object.keys(allStrings).length ? allStrings.reset_likes : "RESET LIKES"}
                              </button>
                            </div>
                        }
                        {autoNavigateToFavourite
                          ? <>
                              <div className='autoNavigateCount description mt-2'>
                                {allStrings.redirecting_to_your_favorite_girl_in ? allStrings.redirecting_to_your_favorite_girl_in : "Redirecting to your favorite girl in "} 
                                <span className='text-primary fw-bold'>{` ${autoNavigateFavCount} `}</span> 
                                {allStrings.seconds ? allStrings.seconds : " Seconds"}
                              </div>
                            </>
                          : <></>
                        }
                      </div>
                    : <></>
                  }
                </div>
            }
            <div className="modal fade" id="" ref={removeLikesConfirmBoxRef}>
              <div className="modal-dialog modal-dialog-centered d-flex justify-content-center align-items-center">
                <div className="modal-content removeLikesConfirmBox border-0">
                  <div className="modal-body d-flex justify-content-between align-items-center flex-column p-4">
                    <img fill={"#FF2121"} height={75} width={75} src={dangerIcon} alt='' />
                    <div className='removeLikesConfirmBoxText text-center my-2'>{Object.keys(allStrings).length ? allStrings.are_you_sure : "Are you sure?"}</div>
                    <div className='removeLikesConfirmBoxDesc text-center mb-3'>{Object.keys(allStrings).length ? allStrings.you_want_to_reset_your_likes_and_start_swiping_from_scratch : "you want to reset your likes and start swiping from scratch?"}</div>
                    <div className='d-flex justify-content-center w-100'>
                      <button className='border-0 text-white bg-primary mx-2' onClick={() => handleResetLikes()}>
                        {allStrings.yes ? allStrings.yes : "Yes"}
                      </button>
                      <button className='bg-none border-0 text-primary border-primary mx-1' onClick={() => removeLikesModal.hide()}>
                        {allStrings.no ? allStrings.no : "No"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        : <></>
      }
    </>
  )
}

export default CardContainer