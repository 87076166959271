import React from 'react'
import "./MyAccount.css"
import leftArrow from "../images/arrow-left.svg"
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../Routes'
import Loader from './Loader'
import { useEffect } from 'react'
import axios from 'axios'
import { baseURL, relativeURL } from '../APIS'
import editIcon from "../images/Mask group (6).svg"
import EditEscortProfile from './EditEscortProfile'
import editEscortIcon from "../images/editbutton.svg"
// import { convertDistance, getDistance } from 'geolib';

const MyAccount = () => {
  const navigate = useNavigate()
  const [showLoader, setShowLoader] = useState(false);
  const [user, setUser] = useState({});
  const [allStrings, setAllString] = useState({})
  const [showEditProfile, setShowEditProfile] = useState(false)
  const sessionStoreAllString = sessionStorage.getItem("allStrings");

  useEffect(() => {
    let userDetails = sessionStorage.getItem("userVerified")
    if(userDetails) {
      userDetails = JSON.parse(userDetails);
      setShowLoader(true)
      let data = {
        user_id: userDetails.id
      }
      axios.post(baseURL + relativeURL.getProfile, data).then((res) => {
        setShowLoader(false)
        if(res.data.success) {
          let tempUser = res.data.data
          setUser(tempUser)
          // if(tempUser.user_role==="escorts") {
          //   if (navigator.geolocation) {
          //     navigator.permissions.query({ name: "geolocation" }).then((result) => {
          //       if (result.state === "granted") {
          //         getLocation()
          //       } else if (result.state === "prompt") {
          //         getLocation()
          //       } else if (result.state === "denied") {
          //         // alert("Please enable location access in your browser's settings.");
          //       }
          //     }).catch(err => console.log(err));
          //   } else {
          //     console.log("Geolocation is not supported by this browser.");
          //   }
          // }
        }
      }).catch((err) => {
        setShowLoader(false)
        console.log(err)
      })
    } else {
      navigate(routes.card)
    }
  }, [navigate])

  useEffect(() => {
    let tempAllStrings = sessionStorage.getItem("allStrings")
    if(tempAllStrings) {
      tempAllStrings = JSON.parse(tempAllStrings);
      setAllString(tempAllStrings)
    }
  }, [sessionStoreAllString])

  // const getLocation = () => {
  //   const successCallback = (position) => {
  //     let temp = {latitude: position.coords.latitude, longitude: position.coords.longitude}
  //     console.log("escortsLatLon", JSON.stringify(temp))
  //     sessionStorage.setItem("escortsLatLon", JSON.stringify(temp))
  //   };
    
  //   const errorCallback = (error) => {
  //     console.log(error);
  //   };
    
  //   let options = { enableHighAccuracy: true, maximumAge: 5000 }
  //   navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
  // }

  const handleEditNumber = () => {
    sessionStorage.setItem("routeFrom", routes.myAccount)
    sessionStorage.setItem("editNumber", JSON.stringify(user.mobile_no))
    sessionStorage.setItem("countryCode", JSON.stringify(user.phone_code))
    navigate(routes.enterMobileNumber)
  }

  const handleBackBtn = () => {
    if(showEditProfile) {
      setShowEditProfile(false)
    } else {
      navigate(routes.card)
    }
  }

  const handleEditProfileClick = () => {
    setShowEditProfile(true)
  }

  return (
    <>
      <div className='myAccountContainer p-3 d-flex justify-content-start align-items-center flex-column'>
        <Loader show={showLoader} />
        <div className={`myAccountHeading d-flex w-100 justify-content-${(user?.user_role==="escorts" && !showEditProfile) ? 'between' : 'center'} align-items-center position-relative mb-4`}>
          <button className={`bg-none border-0 px-0 ${(user?.user_role==="escorts" && !showEditProfile) ? 'myAccountBackBtn' : 'backBtn'}`} onClick={() => handleBackBtn()}>
            <img src={leftArrow} alt='' />
          </button>
          <div className='myAccountHeadingTitle text-primary d-flex'>{allStrings.my_account ? allStrings.my_account : "My Account"}</div>
          {(user?.user_role==="escorts" && !showEditProfile)
            ? <button className='border-0 bg-none text-primary px-0 d-flex align-items-center' onClick={handleEditProfileClick}>
                <img src={editEscortIcon} alt='' height={20} width={20} />
              </button>
            : <></>
          }
        </div>
        {showEditProfile
          ? <>
              <EditEscortProfile escortProfile={user} />
            </>
          : <>
              { Object.keys(user).length
                ? <div className='w-100 d-flex align-items-start flex-column'>
                    {/* <div className='d-flex align-items-start flex-column mb-3'>
                      <div className='myAccountKeys'>Role </div>
                      <div className='myAccountDetails'>{user.user_role}</div>
                    </div> */}
                    { user.user_role==="escorts"
                      ? <>
                          { user.name 
                            ? <div className='d-flex align-items-start flex-column mb-2'>
                                <div className='myAccountKeys'> {allStrings.name ? allStrings.name : "Name"} </div>
                                <div className='myAccountDetails'>{user.name}</div>
                              </div>
                            : <></>
                          }
                          { user.email 
                            ? <div className='d-flex align-items-start flex-column mb-2'>
                                <div className='myAccountKeys'> {allStrings.email ? allStrings.email : "Email"} </div>
                                <div className='myAccountDetails'>{user.email}</div>
                              </div>
                            : <></>
                          }
                          { user.age 
                            ? <div className='d-flex align-items-start flex-column mb-2'>
                                <div className='myAccountKeys'> {allStrings.age ? allStrings.age : "Age"} </div>
                                <div className='myAccountDetails'>{user.age}</div>
                              </div>
                            : <></>
                          }
                          { user.mobile_no 
                            ? <div className='d-flex align-items-start flex-column mb-2'>
                                <div className='myAccountKeys'>{allStrings.mobile_number ? allStrings.mobile_number : "Mobile Number"} </div>
                                <div className='myAccountDetails'>{user.mobile_no}</div>
                              </div>
                            : <></>
                          }
                          { user.phone_code 
                            ? <div className='d-flex align-items-start flex-column mb-2'>
                                <div className='myAccountKeys'> {allStrings.country_code ? allStrings.country_code : "Country Code"} </div>
                                <div className='myAccountDetails'>{user.phone_code}</div>
                              </div>
                            : <></>
                          }
                          { user.description 
                            ? <div className='d-flex align-items-start flex-column mb-2'>
                                <div className='myAccountKeys'> {allStrings.description ? allStrings.description : "Description"} </div>
                                <div className='myAccountDetails'>{user.description}</div>
                              </div>
                            : <></>
                          }
                          { user.hourly_price 
                            ? <div className='d-flex align-items-start flex-column mb-2'>
                                <div className='myAccountKeys'> {allStrings.hourly_price ? allStrings.hourly_price : "Hourly Price"} </div>
                                <div className='myAccountDetails'>{user.hourly_price}</div>
                              </div>
                            : <></>
                          }
                          <div className='d-flex align-items-start flex-column mb-2'>
                            <div className='myAccountKeys'> {allStrings.status ? allStrings.status : "Status"} </div>
                            <div className='myAccountDetails'>{user.is_available ? "Available" : "Not Available"}</div>
                          </div>
                          {/* { user.country_name 
                            ? <div className='d-flex align-items-start flex-column mb-2'>
                                <div className='myAccountKeys'> {allStrings.country_name ? allStrings.country_name : "Country Name"} </div>
                                <div className='myAccountDetails'>{user.country_name}</div>
                              </div>
                            : <></>
                          }
                          { user.state_name 
                            ? <div className='d-flex align-items-start flex-column mb-2'>
                                <div className='myAccountKeys'> {allStrings.state_name ? allStrings.state_name : "State Name"} </div>
                                <div className='myAccountDetails'>{user.state_name}</div>
                              </div>
                            : <></>
                          }
                          { user.city_name
                            ? <div className='d-flex align-items-start flex-column mb-2'>
                                <div className='myAccountKeys'> {allStrings.city_name ? allStrings.city_name : "City Name"} </div>
                                <div className='myAccountDetails'>{user.city_name}</div>
                              </div>
                            : <></>
                          } */}
                          <div className='d-flex align-items-start flex-column w-100'>
                            <div className='myAccountKeys mb-2'> {allStrings.uploaded_images ? allStrings.uploaded_images : "Uploaded Images"} </div>
                            <div className='row ms-0 w-100 g-3'>
                              {user.escort_images?.map((item, index) => {
                                return(
                                  <div key={index} className='col-6 myAccountImgColumns'>
                                    <div className='myAccountImage' style={{backgroundImage: `url("${item.file_path}")`}}></div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </>
                      : <>
                          <div className='d-flex justify-content-between align-items-center w-100'>
                            <div className='d-flex align-items-start flex-column mb-3'>
                              <div className='myAccountKeys'>{allStrings.mobile_number ? allStrings.mobile_number : "Mobile Number"} </div>
                              <div className='myAccountDetails'>{user.phone_code ? (user.phone_code + " ") : ""}{user.mobile_no}</div>
                            </div>
                            <button className='myAccountEditNumberBtn bg-none border-0 d-flex align-items-center justify-content-center' onClick={handleEditNumber}>
                              <img src={editIcon} alt='' />
                            </button>
                          </div>
                        </>
                    }
                  </div>
                : <div className='w-100 d-flex justify-content-center align-items-center text-primary fw-bold'>{allStrings.account_not_found ? allStrings.account_not_found : "Account not Found !"}</div>
              }
            </>
        }
      </div>
    </>
  )
}

export default MyAccount