import React, { useRef, useEffect } from 'react';
import Hammer from 'hammerjs';
import closeImg from "../images/Group 427318773.svg"
import likeImg from "../images/Group 427318774.svg"
import rightImg from "../images/next 1.svg"

const SwipeCard = ({character, onSwipe, i, onImageChange, characters, onBookNowBtnClick, onLikeUnLikeBtnClick, activeIndex, currentPhotoNumber, allStrings}) => {
  const cardRef = useRef(null);
  let dragCardValues = {}

  useEffect(() => {
    if(cardRef.current) {
      const hammer = new Hammer(cardRef.current);
      cardRef.current.style.transform = ''
      // hammer.on('pan', (event) => {
      //   // cardRef.current.classList.add('moving');
      // });
  
      hammer.on('pan', (event) => {
        if (event.deltaX === 0) return;
        if (event.center.x === 0 && event.center.y === 0) return;

        const xMulti = event.deltaX * 0.03;
        const yMulti = event.deltaY / 80;
        const rotate = xMulti * yMulti;

        // event.target.style.transform = `translate(${event.deltaX}px, ${event.deltaY}px) rotate(${rotate}deg)`;
        cardRef.current.style.transform = `translate(${event.deltaX}px, ${event.deltaY}px) rotate(${rotate}deg)`;
        // cardRef.current.style.transform = `translate(${event.deltaX}px, ${event.deltaY}px)`;
      });

      cardRef.current.addEventListener("touchend", (event) => {
        if(event){ 
          if(cardRef.current) {
            cardRef.current.style.transform = ''
          }
        }
      })
  
      hammer.on('panend', (event) => {
        // const threshold = 150;
        // if (event.distance > threshold) {
        //   const direction = event.deltaX > 0 ? 'right' : 'left';
        //   onSwipe(character, direction, i);
        // }
  
        // cardRef.current.style.transform = '';
        // cardRef.current.classList.remove('moving');

        const moveOutWidth = document.body.clientWidth;
        const keep = Math.abs(event.deltaX) < 80 || Math.abs(event.velocityX) < 0.5;

        // event.target.classList.toggle('removed', !keep);

        if (keep) {
          cardRef.current.style.transform = ''
          event.target.style.transform = '';
        } else {
          const endX = Math.max(Math.abs(event.velocityX) * moveOutWidth, moveOutWidth);
          const toX = event.deltaX > 0 ? endX : -endX;
          const endY = Math.abs(event.velocityY) * moveOutWidth;
          const toY = event.deltaY > 0 ? endY : -endY;
          const xMulti = event.deltaX * 0.03;
          const yMulti = event.deltaY / 80;
          const rotate = xMulti * yMulti;

          cardRef.current.style.transform = `translate(${toX}px, ${toY + event.deltaY}px) rotate(${rotate}deg)`
          // event.target.style.transform = `translate(${toX}px, ${toY + event.deltaY}px) rotate(${rotate}deg)`;
          // if(toX > 0) {
          const direction = toX > 0 ? 'right' : 'left';
          onSwipe(character, direction, i);
          // }
        }
      });
  
      return () => {
        hammer.destroy();
        if(cardRef.current){
          cardRef.current.removeEventListener("touchend" , (event) => {
            if(event){ 
              if(cardRef.current) {
                cardRef.current.style.transform = ''
              }
            }
          })
        }
      };
    }
  }, [onSwipe, cardRef.current]);

  const onCardDragStart = (e) => {
    dragCardValues = { clientX: e.clientX, clientY: e.clientY }
  }
  const onCardDragEnd = (e) => {
    if (dragCardValues.clientX === e.clientX && dragCardValues.clientY === e.clientY) {
      let coords = { clientX: e.clientX, clientY: e.clientY };
      onImageChange(coords);
    }
  }

  return (
    <>
      <div ref={cardRef} style={{ backgroundImage: (i === activeIndex ? ('url("' + character.escort_images[currentPhotoNumber]?.file_path + '")') : `url("${character.escort_images[0]?.file_path}")`) }} className={'card position-absolute w-100 m-0 border-0 justify-content-between' + (i === (characters.length - 2) ? " card1" : "") + (i === (characters.length - 3) ? " card2" : "")}>
        <div className='mt-2 mx-4 d-flex justify-content-center imageCounterContainer'>
          {Array(character.escort_images.length).fill()?.map((item, idx) => {
            return (
              <div className={`h-100 mx-1 imageCounter ${(activeIndex === i) ? (currentPhotoNumber === idx ? "bg-activeImg" : "bg-inactiveImg") : (idx === 0 ? "bg-activeImg" : "bg-inactiveImg")}`} style={{ width: "calc(100% / " + character.escort_images.length + ")" }} key={idx}></div>
            )
          })}
        </div>
        <button id={"imageChangeBtn_" + i} className='w-100 h-100 border-0 imageChangerBtn d-flex justify-content-between align-items-center px-4' onMouseDown={onCardDragStart} onMouseUp={onCardDragEnd}>
          <div className='tapImageChangeContainer bg-white d-flex justify-content-center align-items-center p-2'>
            <img className='leftTapImageChange tapImageChange' src={rightImg} alt='' />
          </div>
          <div className='tapImageChangeContainer bg-white d-flex justify-content-center align-items-center p-2'>
            <img className='tapImageChange' src={rightImg} alt='' />
          </div>
        </button>
        <div className={'cardInfoContainer p-2'}>
          <div className='w-100 justify-content-between align-items-center d-flex px-2 mb-3'>
            <div>
              <div className='heading_h3 text-white'>{character.name}, {character.age}</div>
              <div className='escortNearBy d-flex'>¥ {character.hourly_price}</div>
            </div>
            <span className="isAvailable badge rounded-pill bg-light text-dark d-flex justify-content-between align-items-center">
              <div className={'isAvailableContainer me-1' + (character.is_available ? " isAvailableColor" : " isNotAvailableColor")}></div>
              <span>{(character.is_available ? "Ava" : "Not Ava")}</span>
            </span>
          </div>
          <div className='d-flex justify-content-center align-items-center'>
            <button className='optionsBtn border-0 p-0' onClick={() => onLikeUnLikeBtnClick("left", character, i)}>
              <img className='optionsBtnImg' src={closeImg} alt='' />
            </button>
            <button className={`bookNowBtn py-2 px-3 text-primary bg-white ${character.is_available ? "" : "opacity6"}`} onClick={() => onBookNowBtnClick(character)}>
              {allStrings.book_now ? allStrings.book_now : "Book Now"}
            </button>
            <button className='optionsBtn border-0 p-0' onClick={() => onLikeUnLikeBtnClick("right", character, i)}>
              <img className='optionsBtnImg' src={likeImg} alt='' />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SwipeCard;
