import React, { useEffect, useRef, useState } from 'react'
// import flagJapan from "../images/Flag_of_Japan 1.png"
import "./EnterMobileNumber.css"
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { useNavigate } from 'react-router-dom';
import { routes } from '../Routes';
import axios from 'axios';
import { baseURL, relativeURL } from "../APIS"
import Loader from './Loader';
import editIcon from "../images/Mask group (2).svg"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import profileImg from "../images/Mask group.png"
import headerImg from "../images/Group 427318752.png"
import "./Card.css";
import { messaging } from "../firebase";
import { getToken } from "firebase/messaging";

const EnterMobileNumber = () => {
  const navigate = useNavigate();
  const isKeyboardOpen = useDetectKeyboardOpen();
  const [mobileNum, setMobileNumber] = useState("")
  const [countryCode, setCountryCode] = useState("+81")
  const [enterCodeVisible, setEnterCodeVisible] = useState(false);
  const [mobileNumberAlert, setMobileNumberAlert] = useState(false);
  const [otpArr, setOtpArr] = useState(['', '', '', '', '', '']);
  // const [authenticateOTP, setAuthenticateOTP] = useState(0)
  const [seconds, setSeconds] = useState(60);
  const timerRef = useRef(null);
  const [otpVerificationError, setOtpVerificationError] = useState(false)
  const [otpVerificationMsg, setOtpVerificationMsg] = useState(false)
  const [showResendOtpBtn, setShowResendOtpBtn] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [lastVisitedRoute, setLastVisitedRoute] = useState("")
  const [allStrings, setAllString] = useState({})
  const sessionStoreAllString = sessionStorage.getItem("allStrings");
  const [resendOtpErrMsg, setResendOtpErrMsg] = useState(false);
  const [termsPrivacy, setTermsPrivacy] = useState({})
  const sessionStoreTermsPrivacy = sessionStorage.getItem("termsPrivacy");
  const [userOldNumber, setUserOldNumber] = useState("")
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isEscort, setIsEscort] = useState(false)
  const [showModalLoginBtn, setShowModalLoginBtn] = useState(false)

  useEffect(() => {
    // requestPermission()
    clearInterval(timerRef.current)
    let tempLastVisited = ""
    if (sessionStorage.getItem("userVerified")) {
      setUserLoggedIn(true)
      let userDetails = JSON.parse(sessionStorage.getItem("userVerified"))
      if (userDetails.user_role === "escorts") {
        setIsEscort(true)
      }
    } else {
      setUserLoggedIn(false)
    }
    if(sessionStorage.getItem("showModalLoginBtn")){
      let tempShowModalLoginBtn = JSON.parse(sessionStorage.getItem("showModalLoginBtn"))
      if(tempShowModalLoginBtn === false || tempShowModalLoginBtn === true) {
        setShowModalLoginBtn(tempShowModalLoginBtn)
      }
    }
    if (sessionStorage.getItem("routeFrom")) {
      setLastVisitedRoute(sessionStorage.getItem("routeFrom"))
      tempLastVisited = sessionStorage.getItem("routeFrom")
      if (tempLastVisited === routes.myAccount) {
        let tempNumber = sessionStorage.getItem("editNumber")
        setCountryCode(JSON.parse(sessionStorage.getItem("countryCode")))
        setUserOldNumber(JSON.parse(tempNumber))
        tempNumber = tempNumber.replace(/\D/g, '')
        tempNumber = tempNumber.slice(-10);
        let formattedNumber = '';
        if (tempNumber.length <= 10) {
          for (let i = 0; i < tempNumber.length; i++) {
            if (i === 3 || i === 6) {
              formattedNumber += '-';
            }
            formattedNumber += tempNumber[i];
          }
          setMobileNumber(formattedNumber)
        }
      }
    }
    setEnterCodeVisible(false);
    setShowResendOtpBtn(false);
    if (tempLastVisited === routes.card) {
      let escort = sessionStorage.getItem("escortBooking")
      if (escort) {
      } else {
        navigate(routes.card)
      }
    }
  }, [navigate])

  useEffect(() => {
    let tempAllStrings = sessionStorage.getItem("allStrings")
    if (tempAllStrings) {
      tempAllStrings = JSON.parse(tempAllStrings);
      setAllString(tempAllStrings)
    }
  }, [sessionStoreAllString])

  useEffect(() => {
    let tempTermsPrivacy = sessionStorage.getItem("termsPrivacy")
    if (tempTermsPrivacy) {
      tempTermsPrivacy = JSON.parse(tempTermsPrivacy);
      setTermsPrivacy(tempTermsPrivacy)
    }
  }, [sessionStoreTermsPrivacy])

  const onOurTermsClick = () => {
    const url = termsPrivacy.terms_condition_link;
    // Open the URL in a new tab
    window.open(url, '_blank');
  }
  const onOurPrivacyClick = () => {
    const url = termsPrivacy.privacy_policy_link;
    // Open the URL in a new tab
    window.open(url, '_blank');
  }

  const onGetOTP = () => {
    if (mobileNum.length >= 1) {
      let tempNumber = mobileNum.replace(/\D/g, '')
      let data = { phone_code: countryCode, mobile_no: tempNumber }
      setShowLoader(true);
      axios.post(baseURL + relativeURL.sendOtp, data).then((res) => {
        setShowLoader(false);
        if (res.data.success) {
          setEnterCodeVisible(true)
          setSeconds(60);
          // setAuthenticateOTP(res.data.data.mobile_otp);
          timerRef.current = setInterval(() => {
            setSeconds((prevSeconds) => {
              if (prevSeconds > 0) {
                return prevSeconds - 1;
              } else {
                setShowResendOtpBtn(true)
                clearInterval(timerRef.current);
                // Handle countdown completion here (e.g., show a message)
                return 0;
              }
            })
          }, 1000);
        } else {
          console.log(res.data)
        }
      }).catch(err => {
        setShowLoader(false);
        setMobileNumberAlert(true)
        setTimeout(() => {
          setMobileNumberAlert(false)
        }, 3000)
        console.log(err)
      })
    } else {
      setMobileNumberAlert(true)
      setTimeout(() => {
        setMobileNumberAlert(false)
      }, 3000)
    }
  }

  const onMobileNumberInpChange = (e) => {
    const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    let formattedNumber = '';
    // if (input.length <= 10) {
    for (let i = 0; i < input.length; i++) {
      if (i === 3 || i === 6) {
        formattedNumber += '-';
      }
      formattedNumber += input[i];
    }
    setMobileNumber(formattedNumber)
    // }
  }

  const handleOtpChange = (index, event) => {
    const value = event.target.value;
    if (isNaN(value)) {
      // Ensure only numeric values are entered
      return;
    }
    const newOtp = [...otpArr];
    newOtp[index] = value;
    setOtpArr(newOtp);
    // Focus on the next input box
    if (index < otpArr.length - 1 && value !== '') {
      document.getElementById(`otpInput${index + 1}`).focus();
    }
  };

  const handleOtpKeyDown = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && otpArr[index] === '') {
      // Move focus to the previous input box upon backspace
      document.getElementById(`otpInput${index - 1}`).focus();
    }
  }

  const onSubmitOTP = (filter) => {
    if (!showResendOtpBtn) {
      let tempOtp = otpArr.join(',')
      tempOtp = tempOtp.replace(/,/g, "")
      let tempNumber = mobileNum.replace(/\D/g, '')
      let data = {}
      let flag = true;
      if (filter === "update") {
        if (mobileNum.length >= 1) {
          let tempUserDetails = JSON.parse(sessionStorage.getItem("userVerified"))
          data = {
            user_id: tempUserDetails.id,
            old_mobile_no: userOldNumber,
            phone_code: countryCode,
            mobile_no: tempNumber,
          }
          console.log(data);
        } else {
          flag = false
        }
      } else {
        data = { phone_code: countryCode, mobile_no: tempNumber, mobile_otp: tempOtp }
      }
      if (flag) {
        setShowLoader(true);
        axios.post(baseURL + (filter === "update" ? relativeURL.userUpdateProfile : relativeURL.verifyOtp), data).then((res) => {
          setShowLoader(false);
          if (res.data.success) {
            let userDetails = res.data.data
            // setCurrentLocation(res.data.data)
            requestPermission(userDetails.id)
            sessionStorage.setItem("userVerified", JSON.stringify(res.data.data))
            if (userDetails.user_role === "client") {
              if (lastVisitedRoute === routes.card) {
                let likedArr = sessionStorage.getItem("likedArr")
                if (likedArr) {
                  likedArr = JSON.parse(likedArr)
                  if (likedArr.length) {
                    pushLikedArr(routes.bookingPage, userDetails)
                  } else {
                    navigate(routes.bookingPage)
                  }
                } else {
                  navigate(routes.bookingPage)
                }
              } else if (lastVisitedRoute === routes.favorites) {
                // navigate(routes.favorites)
                let likedArr = sessionStorage.getItem("likedArr")
                if (likedArr) {
                  likedArr = JSON.parse(likedArr)
                  if (likedArr.length) {
                    pushLikedArr(routes.bookingPage, userDetails)
                  } else {
                    navigate(routes.bookingPage)
                  }
                } else {
                  navigate(routes.bookingPage)
                }
              } else if (lastVisitedRoute === routes.loginForm) {
                // navigate(routes.card)
                let likedArr = sessionStorage.getItem("likedArr")
                if (likedArr) {
                  likedArr = JSON.parse(likedArr)
                  if (likedArr.length) {
                    pushLikedArr(routes.card, userDetails)
                  } else {
                    navigate(routes.card)
                  }
                } else {
                  navigate(routes.card)
                }
              } else if (lastVisitedRoute === routes.myAccount) {
                sessionStorage.removeItem("editNumber")
                // navigate(routes.myAccount)
                let likedArr = sessionStorage.getItem("likedArr")
                if (likedArr) {
                  likedArr = JSON.parse(likedArr)
                  if (likedArr.length) {
                    pushLikedArr(routes.myAccount, userDetails)
                  } else {
                    navigate(routes.myAccount)
                  }
                } else {
                  navigate(routes.myAccount)
                }
              }
              sessionStorage.removeItem("likedArr")
              sessionStorage.removeItem("routeFrom")
            } else {
              // When its Escort
              // navigate(routes.myAccount)
              navigate(routes.card)
            }
          } else {
            // setEnterCodeVisible(false);
            // setShowResendOtpBtn(false);
            // setOtpArr(["", "", "", "", "", ""])
            // setOtpVerificationError(true)
            // setTimeout(() => {
            //   setOtpVerificationError(false)
            // }, 3000)
          }
        }).catch(err => {
          console.log(err)
          setShowLoader(false);
          if (filter === "update") {
          } else {
            setShowResendOtpBtn(false)
            setEnterCodeVisible(true)
            setOtpArr(["", "", "", "", "", ""])
          }
          setOtpVerificationMsg(err.response.data.message)
          setOtpVerificationError(true)
          setTimeout(() => {
            setOtpVerificationError(false)
          }, 3000)
        })
      } else {
        setMobileNumberAlert(true)
        setTimeout(() => {
          setMobileNumberAlert(false)
        }, 3000)
      }
    } else {
      setResendOtpErrMsg(true)
      setOtpArr(["", "", "", "", "", ""])
      setTimeout(() => {
        setResendOtpErrMsg(false)
      }, 3000)
    }
  }

  const pushLikedArr = (route, userDetails) => {
    let likedArr = sessionStorage.getItem("likedArr")
    if (likedArr) {
      likedArr = JSON.parse(likedArr)
      if (likedArr.length) {
        let escortsListData = { user_id: userDetails.id, filter: "is_favourite" }
        setShowLoader(true)
        axios.post(baseURL + relativeURL.escortsList, escortsListData).then(res => {
          setShowLoader(false)
          if (res.data.success) {
            let tempData = res.data.data
            let likedArrToAdd = likedArr.filter((likedItem) => {
              let flag = true;
              for (let i = 0; i < tempData.length; i++) {
                if (likedItem.id === tempData[i].id) {
                  flag = false
                  break
                }
              }
              if (flag) {
                return true
              } else {
                return false
              }
            })
            let uniqueArray = [];
            let seenIds = new Set();
            for (let obj of likedArrToAdd) {
              if (!seenIds.has(obj.id)) {
                seenIds.add(obj.id);
                uniqueArray.push(obj);
              }
            }
            if (uniqueArray.length) {
              let arrayOfIds = []
              arrayOfIds = uniqueArray.map(obj => obj.id)
              let ids = arrayOfIds.join()
              let data = { escort_ids: ids, user_id: userDetails.id, is_favourite: 1 }
              setShowLoader(true);
              axios.post(baseURL + relativeURL.favouriteEscort, data).then((res) => {
                setShowLoader(false);
                console.log(res)
                navigate(route)
              }).catch(err => {
                setShowLoader(false);
                console.log(err)
              })
            } else {
              navigate(route)
            }
          }
        }).catch(err => {
          setShowLoader(false)
          console.log(err)
        })
      }
    }
  }

  const onResendOTP = () => {
    let tempNumber = mobileNum.replace(/\D/g, '')
    let data = { phone_code: countryCode, mobile_no: tempNumber }
    setShowLoader(true);
    clearInterval(timerRef.current)
    axios.post(baseURL + relativeURL.sendOtp, data).then((res) => {
      setShowLoader(false);
      if (res.data.success) {
        setEnterCodeVisible(true)
        setShowResendOtpBtn(false)
        setOtpArr(["", "", "", "", "", ""])
        setSeconds(60);
        // setAuthenticateOTP(res.data.data.mobile_otp);
        timerRef.current = setInterval(() => {
          setSeconds((prevSeconds) => {
            if (prevSeconds > 0) {
              return prevSeconds - 1;
            } else {
              setShowResendOtpBtn(true)
              clearInterval(timerRef.current);
              // Handle countdown completion here (e.g., show a message)
              return 0;
            }
          })
        }, 1000);
      } else {
        console.log(res.data)
      }
    }).catch(err => {
      setShowLoader(false);
      console.log(err)
    })
  }

  // const setCurrentLocation = (userDetails) => {
  //   const getLocation = () => {
  //     const successCallback = (position) => {
  //       let data = {
  //         user_id: userDetails.id,
  //         latitude: position.coords.latitude,
  //         longitude: position.coords.longitude
  //       }
  //       setShowLoader(true)
  //       axios.post(baseURL + relativeURL.updateLocation, data).then((res) => {
  //         setShowLoader(false)
  //         console.log(res)
  //       }).catch((err) => {
  //         console.log(err)
  //         setShowLoader(false)
  //       })
  //     };

  //     const errorCallback = (error) => {
  //       console.log(error);
  //     };

  //     let options = { enableHighAccuracy: true, maximumAge: 5000 }
  //     navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
  //   }
  //   if (navigator.geolocation) {
  //     navigator.permissions.query({ name: "geolocation" }).then((result) => {
  //       if (result.state === "granted") {
  //         getLocation()
  //       } else if (result.state === "prompt") {
  //         getLocation()
  //       } else if (result.state === "denied") {
  //         alert("Please enable location access in your browser's settings.");
  //       }
  //     }).catch(err => console.log(err));
  //   } else {
  //     console.log("Geolocation is not supported by this browser.");
  //   }
  // }

  const handleDropdown = () => {
    let card = document.querySelector(".cardDropdown"); //declearing profile card element
    card.classList.toggle("hidden")
  }

  const requestPermission = async (userId) => {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then(async (registration) => {
      setShowLoader(true)
      console.log('Service Worker registered with scope:', registration.scope);
      // Request notification permissions and get FCM token
      const swRegistration = await navigator.serviceWorker.ready;
      console.log("swRegistration", swRegistration)
      const permission = await Notification.requestPermission();
      console.log("permission", permission)
      if(permission === "granted" || permission === "prompt") {
        // console.log('Notification permission granted.');
        // Get the FCM token
        return getToken(messaging, {
          vapidKey:
            "BHNdTL_GGvGssYcC5TVlYL7uqx_Sk_6scfUmljHSTHVHySs0o0H-7pdG4Zs9Mo3Hb3WHSsyD-nGB1nvMCri78W0",
          serviceWorkerRegistration: swRegistration
        });
      } else if (permission === "denied") {
        setShowLoader(false)
        console.log("You denied permission for notifications");
      }
    })
    .then((token) => {
      setShowLoader(false)
      if (token) {
        console.log("Token Gen :", token);
        if(userId){
          const data = {
            user_id: userId,
            device_token: token 
          }
          setShowLoader(true)
          axios.post(baseURL + relativeURL.userUpdateDeviceToken, data).then((res) => {
            setShowLoader(false)
            console.log(res)
          }).catch((err) => {
            setShowLoader(false)
            console.log(err)
          })
        }
      }
      // You can send the token to your server for further use
    })
    .catch((error) => {
      console.error('Error initializing Firebase:', error);
    });
  }

  // const handleGetToken = (userId) => {
  //   getToken(messaging, {
  //     vapidKey:
  //       "BIGQOOnaZ1Wa8xJ49S_kvEBIY8LbKYE3zkVGmvV4opCi-RKq-ZQifFJI22QfCwm7y1UewtgFlvlwFYNSpoJe9NE",
  //   }).then((token) => {
  //     if (token) {
  //       console.log("Token Gen :", token);
  //       if (userId) {
  //         const data = {
  //           user_id: userId,
  //           device_token: token
  //         }
  //         setShowLoader(true)
  //         axios.post(baseURL + relativeURL.userUpdateDeviceToken, data).then((res) => {
  //           setShowLoader(false)
  //           console.log(res)
  //         }).catch((err) => {
  //           setShowLoader(false)
  //           console.log(err)
  //         })
  //       }
  //     }
  //   }).catch((err) => {
  //     // window.location.reload()
  //     console.log("token err", err)
  //     if (getTokenTry === 0) {
  //       getTokenTry = getTokenTry + 1
  //       handleGetToken(userId)
  //     }
  //   })
  // }

  return (
    <>
      <Loader show={showLoader} />
      <div className='EnterMobileNumContainer d-flex align-items-center justify-content-center'>
        <div className='EnterMobileNumContainer2 d-flex align-items-center justify-content-center flex-column p-3'>
          <div className='enterMobileNumberHeader d-flex justify-content-between w-100 align-items-center mb-3'>
            <div className='d-flex justify-content-start align-items-center' onClick={() => navigate(routes.home)}>
              <div><img className='headerImg' src={headerImg} alt='' /></div>
              <div className='cardHeaderTitle headertext'>EscortsJP.com</div>
            </div>
            <div className="profile_dropdown">
              <ul>
                {userLoggedIn
                  ? <>
                      <a href="*" onClick={(e) => { e.preventDefault() }} className="display-pictureDropdown">
                        <img src={profileImg} alt='' onClick={() => {
                          if (userLoggedIn) {
                            handleDropdown()
                          } else {
                            sessionStorage.setItem("routeFrom", routes.loginForm);
                            sessionStorage.setItem("showModalLoginBtn", JSON.stringify(true));
                            navigate(routes.enterMobileNumber);
                          }
                        }} />
                      </a>
                    </>
                  : <></>
                }
              </ul>
              <div className="cardDropdown position-relative">
                <ul>
                  <li>
                    {userLoggedIn
                      ? <>
                        <a href="*" onClick={(e) => {
                          e.preventDefault();
                          navigate(routes.myAccount);
                        }}> {allStrings.my_account ? allStrings.my_account : "My Account"} </a>
                        <a href="*" onClick={(e) => {
                          e.preventDefault();
                          navigate(routes.myBookings);
                        }}> {allStrings.my_bookings ? allStrings.my_bookings : "My Bookings"} </a>
                        {isEscort
                          ? <a href="*" onClick={(e) => {
                            e.preventDefault();
                            navigate(routes.manageAvailablity);
                          }}>
                            {allStrings.manage_availability ? allStrings.manage_availability : "Manage Availablity"}
                          </a>
                          : <></>
                        }
                        <a href="*" onClick={(e) => {
                          e.preventDefault();
                          sessionStorage.removeItem("userVerified");
                          navigate(routes.home)
                          // window.location.reload()
                        }}> {allStrings.log_out ? allStrings.log_out : "Log Out"} </a>
                      </>
                      : <></>
                    }
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={'EnterMobileNumContent w-100 d-flex flex-column align-items-center' + (isKeyboardOpen ? " justify-content-start" : " justify-content-center")}>
            <div className='d-flex flex-column align-items-center mt'>
              {enterCodeVisible
                ? <div className='EnterMobileNumTitle'>{Object.keys(allStrings).length ? allStrings.enter_your_code : ""}</div>
                : <div className='EnterMobileNumTitle'>{Object.keys(allStrings).length ? allStrings.enter_your_phone_number : ""}</div>
              }
            </div>
            {enterCodeVisible
              ? <>
                <div className='otpNumber d-flex justify-content-center align-items-center my-4'>
                  <div className='d-flex justify-content-center align-items-center'>{"( " + countryCode + " )  "}</div>
                  <div className='d-flex justify-content-center align-items-center'>{mobileNum} </div>
                  <button className='editNumberIconBtn bg-none d-flex justify-content-center align-items-center border-0 h-100 ms-1' onClick={() => {
                    setEnterCodeVisible(false);
                    clearInterval(timerRef.current);
                    setOtpArr(["", "", "", "", "", ""])
                  }}>
                    <img className='editNumberIcon' src={editIcon} alt='' />
                  </button>
                </div>
                <div className='d-flex flex-column w-100'>
                  <div className='d-flex justify-content-between w-100 otpContainer mt-4 mb-3'>
                    {otpArr.map((digit, index) => (
                      <input key={index} id={`otpInput${index}`} className='border-0 p-2 mx-1 text-center' type="tel" maxLength="1" value={digit} onChange={(e) => handleOtpChange(index, e)} onKeyDown={(e) => handleOtpKeyDown(index, e)} placeholder='X' />
                    ))}
                  </div>
                  <div className='d-flex justify-content-start align-items-center'>
                    {showResendOtpBtn
                      ? <button className='resendOtpBtn w-100 text-primary bg-none d-flex border-0' onClick={() => onResendOTP()}>{allStrings.resend_otp ? allStrings.resend_otp : "Resend OTP"}</button>
                      : <>
                        <div className='resendOtp mx-2'>{Object.keys(allStrings).length ? allStrings.resend_otp : ""} :</div>
                        <div className='resendOtp'>{"00 : " + (seconds ? seconds : "00")}</div>
                      </>
                    }
                  </div>
                </div>
                {/* {showResendOtpBtn
                    ? <button className='getOTPbtn mt-3 w-100 bg-primary text-white d-flex justify-content-center align-items-center border-0 mt-3' onClick={() => onResendOTP()}>Resend OTP</button>
                    : <></>
                  } */}
                {resendOtpErrMsg
                  ? <div className='text-danger w-100 ps-2'>{allStrings.please_resend_otp ? allStrings.please_resend_otp : "Please Resend OTP"}</div>
                  : <></>
                }
                <button className={`getOTPbtn w-100 bg-primary text-white d-flex justify-content-center align-items-center border-0 mt-4 ${showResendOtpBtn ? "opacity6" : ""}`} onClick={() => onSubmitOTP("newUser")}>
                  {Object.keys(allStrings).length ? allStrings.submit : ""}
                </button>
              </>
              : <>
                <div className='d-flex align-items-center justify-content-between w-100 my-4'>
                  <div className='flag-code p-1 d-flex align-items-center justify-content-around'>
                    <PhoneInput disableSearchIcon={true} searchPlaceholder="search" searchNotFound="No entries to show" enableSearch={true} value={countryCode} onChange={(val) => setCountryCode("+" + val)} />
                    {/* <div className='d-flex'>
                      <img src={flagJapan} alt='' />
                    </div> */}
                    <div className='countryCode'>{countryCode}</div>
                  </div>
                  <div className='mobileNumberInputContainer px-2 d-flex align-items-center justify-content-center'>
                    <input className='border-0 w-100 px-2' value={mobileNum} onChange={onMobileNumberInpChange} type='tel' placeholder='XXX - XXX - XXXX' />
                  </div>
                </div>
                {mobileNumberAlert
                  ? <div className='EnterMobileNumTitle text-danger mb-2'>{allStrings.enter_correct_number ? allStrings.enter_correct_number : "Enter Correct Number!"}</div>
                  : <></>
                }
                <div className='mobileNumberDesc mb-4 mt-2'>
                  {Object.keys(allStrings).length ? allStrings.by_clicking_continue__you_agree_with + " " : ""}
                  <span className='loginDescLink' onClick={() => onOurTermsClick()}>
                    {Object.keys(allStrings).length ? allStrings.our_terms + " " : ""}
                  </span>
                  {Object.keys(allStrings).length ? allStrings.learn_how_we_process_your_data_in + " " : ""}
                  <span className='loginDescLink' onClick={() => onOurPrivacyClick()}>
                    {Object.keys(allStrings).length ? allStrings.our_privacy_policy_and_cookies : ""}
                  </span>
                </div>
                {userOldNumber
                  ? <button className='getOTPbtn my-3 w-100 bg-primary text-white d-flex justify-content-center align-items-center border-0' onClick={() => onSubmitOTP("update")}>
                    {allStrings.update_number ? allStrings.update_number : "Update Number"}
                  </button>
                  : <button className='getOTPbtn my-3 w-100 bg-primary text-white d-flex justify-content-center align-items-center border-0' onClick={() => onGetOTP()}>{Object.keys(allStrings).length ? allStrings.get_otp : ""}</button>
                }
              </>
            }
            {otpVerificationError
              ? <div className='text-danger my-2 fw-bold'>{otpVerificationMsg}</div>
              : <></>
            }
          </div>
          {(lastVisitedRoute === routes.loginForm) && showModalLoginBtn
            ? <>
                <span className='d-flex align-items-center justify-content-center mb-2 flex-wrap'>
                  <span>{allStrings.modal ? allStrings.modal : "Modal"} </span> <button className='text-primary fw-bold bg-none border-0 text-underline' onClick={() => navigate(routes.escortLogin)}><u>{allStrings.click_here_to_login ? allStrings.click_here_to_login : "Click here to Login"}</u></button>
                </span>
              </>
            : <></>
          }
        </div>
      </div>
    </>
  )
}

export default EnterMobileNumber