import React, { useState } from 'react'
// import CardHeader from './CardHeader'
import CardContainer from './CardContainer'

const Card = () => {
  const [activeCard, setActiveCard] = useState({})
  return (
    <>
      <div className='card-main-container justify-content-start p-3'>
        {/* <CardHeader activeCard={activeCard} /> */}
        <CardContainer activeCard={activeCard} setActiveCard={setActiveCard} />
      </div>
    </>
  )
}

export default Card